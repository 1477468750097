.ctas {
  position: relative;
  z-index: 10;

  @include susy-media(mobileOnly) {
    margin-bottom: 51px;
  }

  @include susy-media(tablet) {
    bottom: -38px;
  }

  &__wrap {
    @include susy-media(tablet) {
      display: flex;
      justify-content: center;
    }
  }

  &__item {
    position: relative;
    padding-top: 37px;
    width: 100%;
    margin-bottom: 34px;

    @include susy-media(tablet) {
      padding-top: 35px;
      width: calc((100% - 40px) / 3);
      margin-right: 20px;
      margin-bottom: 0;

      &:nth-child(3) {
        margin-right: 0;
      }
    }

    @include susy-media(desktop) {
      padding-top: 50px;
      width: calc((100% - 60px) / 3);
      margin-right: 30px;
    }
  }

  &__group {
    position: absolute;
    top: 0;
    left: 42px;
    z-index: 1;

    @include susy-media(tablet) {
      left: 10px;
      right: 10px;
    }

    @include susy-media(desktopWide) {
      left: 28px;
    }
  }

  &__image {
    line-height: 0;
    background-color: #fafafa;

    img {
      width: 100%;
      height: auto;

      @include susy-media(mobileOnly) {
        -webkit-filter: none;
        filter: none;
      }

      @include susy-media(tablet) {
        // transition: .5s;
        // opacity: .5;
      	// -webkit-filter: grayscale(1);
        // filter: grayscale(1);
      }
    }
  }

  &__link {
    position: absolute;
    bottom: 22px;
    left: 0;
    right: 0;
    text-align: center;

    a {
      z-index: 2;
      position: relative;
    }
  }

  &__subtitle {
    @extend .title--small;
    @include susy-media(tabletOnly) {
      letter-spacing: .5px;
      font-size: 12px;
    }
  }

  &__title {
    font-size: 37px;

    @include susy-media(tabletOnly) {
      font-size: 30px;
    }

    @include susy-media(mobilePortraitOnly) {
      font-size: 28px;
    }

    sup {
      font-size: 20px;
    }

    sub {
      vertical-align: baseline;
      font-size: 25px;
      text-transform: uppercase;
      position: relative;
      top: -2px;
    }
  }

  // Vertical
  &--vertical {
    color: $white;
    bottom: 0;

    @include susy-media(mobileLandscapeOnly) {
      margin-bottom: 15px;
    }

    .ctas {
      &__wrap {
        display: block;

        @include susy-media(mobileTabletOnly) {
          display: flex;
          justify-content: center;
        }

        @include susy-media(mobilePortraitOnly) {
          display: block;
        }
      }

      &__group {
        padding-top: 21px;
        text-align: center;
        left: 10px;
        right: 10px;
        
        @include susy-media(mobileTabletOnly) {
          z-index: 2;
        }

        @include susy-media(mobileLandscapeOnly) {
          padding-top: 17px;
        }

        &.with-sub-title  {
          .ctas__title {
            text-align: left;
          }
        }

        @include susy-media(mobileLandscapeOnly) {
          left: 10px;
        }
      }

      &__title {
        @include susy-media(mobileTabletOnly) {
          font-size: 28px;
        }

        @include susy-media(mobileLandscapeOnly) {
          margin-bottom: 12px;
        }
      }

      &__description {
        @include susy-media(mobileLandscapeOnly) {
          font-size: 13px;
        }

        p {
          margin-bottom: 0;
        }
      }

      &__item {
        padding: 0;
        width: 100%;
        margin-right: 0;
        margin-bottom: 14px;

        @include susy-media(desktop) {
          max-width: 279px;
        }

        @include susy-media(tabletOnly) {
          margin-right: 20px;
            margin-bottom: 30px;

          &:last-child {
            margin-right: 0;
          }
        }

        @include susy-media(mobileLandscapeOnly) {
          margin-right: 10px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }

      &__link {
        bottom: 17px;

        a {
          min-width: 142px;
        }
      }

      &__subtitle {
        color: $white;
        font-size: 13px;
        text-align: left;
        margin-top: -8px;

        @include susy-media(mobileLandscapeOnly) {
          font-size: 10px;
        }
      }

      &__image img {
        opacity: 1;
        filter: none;
      }
    }

    .btn {
      @include susy-media(mobileLandscapeOnly) {
        font-size: 11px;
        padding: 5px 17px 6px;
        min-width: 110px;
      }
    }
  }
}
