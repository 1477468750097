.layout-3cols {
  padding: 45px 0 5px;

  @include susy-media (tablet) {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include susy-media (desktop) {
    padding: 50px 0;
  }

  .ctas {
    @include susy-media (desktop) {
      width: 279px;
    }

    @include susy-media (tabletOnly) {
      width: 100%;
    }
  }

  .description {
    width: 100%;

    @include susy-media (tablet) {
      width: calc(100% - 262px);
      padding-right: 20px;
    }

    @include susy-media (desktop) {
      width: calc(100% - 279px - 262px);
      padding: 0 53px;
    }

    h5 {
      line-height: 1;

      @include susy-media(mobileOnly) {
        font-size: 19px;
      }
    }

    h2 {
      @extend .title--large;

      @include susy-media(mobileOnly) {
        font-size: 50px;
      }
    }

    .field-name-body {
      margin-top: 31px;

      @include susy-media(mobileOnly) {
        margin-top: 22px;
      }

      h3 {
        font-size: 21px;
        line-height: 1;
        margin-bottom: 3px;
        color: $pink;
      }

      p {
        margin-bottom: 13px;
      }
    }
  }

  .form-contact {
    @include susy-media(mobileOnly) {
      display: none;
    }
  }
}

.form-contact {
  width: 262px;

  label {
    font-size: 12px;
  }

  .form-item-captcha-response .description {
    display: none;
  }

  form {
    padding: 18px 12px 13px;
    background-color: $gray-f0;
  }

  .captcha {
    margin-bottom: 10px;

    @include susy-media(desktopOnly) {
      transform: scale(0.84);
      transform-origin: center left;
    }
  }

  .form-item {
    margin-bottom: 12px;
  }

  .webform-component--title {
    margin-bottom: 21px;

    h3 {
      font-size: 28px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .webform-component-textarea {
    margin-top: 15px;
  }

  input[type='tel'],
  .form-url,
  .form-search,
  .form-email,
  .form-text,
  textarea {
    font-size: 12px;
    font-weight: 600;
    height: auto;
    padding: 8px 14px;
  }

  textarea {
    min-height: inherit;
    height: 150px;
  }

  .form-actions {
    margin: 0;

    @include susy-media(mobileTabletOnly) {
      text-align: center;
    }
  }

  .form-submit {
    padding: 7px;
    min-width: inherit;
    width: 100%;
    max-width: 305px;
  }
}

.webform-component-textarea .grippie {
  display: none;
}

.webform-client-form-157,
.webform-client-form-14,
.webform-client-form-158,
.webform-client-form-159,
.webform-client-form-160,
.webform-client-form-161,
.webform-client-form-408 {
  .captcha {
    @include susy-media(tablet) {
      transform: scale(0.78);
      transform-origin: center left;
    }
  }
}

.webform-client-form-27,
.webform-client-form-7 {
  .captcha {
    @media (max-width: 369px) {
      transform: scale(0.84);
      transform-origin: center left;
    }
    @media (min-width: 370px) and (max-width: 1023px) {
      text-align: center;

      >div >div {
        width: auto !important;
      }
    }
  }
}