.box-article {
  @extend .list-style;
  padding: 25px 0 20px;

  @include susy-media(tablet) {
    padding: 52px 0 42px;
  }

  .node-title {
    font-size: 37px;
    margin-bottom: 25px;

    @include susy-media(tablet) {
      font-size: 45px;
      margin-bottom: 37px;
    }

    @include susy-media(desktop) {
      font-size: 50px;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    color: $pink;
  }

  h2 {
    font-size: 28px;

    @include susy-media(tablet) {
      font-size: 32px;
    }

    @include susy-media(desktop) {
      font-size: 37px;
    }
  }

  h3 {
    font-size: 19px;
    margin-top: 14px;

    @include susy-media(tablet) {
      font-size: 24px;
    }

    @include susy-media(desktop) {
      font-size: 28px;
    }
  }

  h4 {
    font-size: 19px;
    margin: 12px 0 13px;

    @include susy-media(tablet) {
      font-size: 21px;
    }
  }

  h5 {
    font-size: 16px;

    @include susy-media(tablet) {
      font-size: 18px;
    }
  }

  h6 {
    font-size: 14px;

    @include susy-media(tablet) {
      font-size: 16px;
    }
  }

  h4, h5, h6 {
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 0;
  }

  ul {
    li {
      padding-left: 14px;
      margin-bottom: 12px;
    }
  }

  b {
    font-weight: 600;
  }

  .field-name-field-image {
    @include susy-media(mobileLandscape) {
      max-width: 150px;
      float: right;
    }

    @include susy-media(tablet) {
      margin-left: 20px;
      margin-top: -70px;
    }

    @include susy-media(mobilePortraitOnly) {
      img {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .btn {
    margin-top: 15px;
    margin-bottom: 14px;
  }
}

.node-full {
  .box-article {
    padding: 30px 0 20px;

    @include susy-media(desktop) {
      padding: 43px 0 42px;
    }
  }
}

.box-content-image {
  margin-bottom: 24px;

  @include susy-media(desktop) {
    margin-bottom: 17px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    @include susy-media(desktop) {
      overflow: hidden;
    }
  }

  &__image {
    margin-left: 15px;
    float: right;
    max-width: 198px;

    @include susy-media(mobilePortraitOnly) {
      max-width: 150px;
    }

    @include susy-media(tablet) {
      margin-left: 30px;
      margin-top: -10px;
      max-width: 250px;
    }

    @include susy-media(desktop) {
      max-width: 300px;
    }

    @include susy-media(desktopWide) {
      max-width: 343px;
    }
  }
}

.group-link {
  @include susy-media(mobileOnly) {
    text-align: center;
  }

  .btn {
    margin-top: 20px;
    margin-right: 10px;

    @include susy-media(mobileOnly) {
      margin-right: 0;
      display: inline-block;
      width: 100%;
      max-width: 365px;
    }

    @include susy-media(mobileLandscapeOnly) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.group-image {
  margin: 28px 0 42px;
  display: flex;
  overflow: auto;

  @include susy-media(tablet) {
    margin: 40px 0 36px;
  }

  img {
    margin: 0 3px;
    max-width: inherit;

    @include susy-media(mobileOnly) {
      min-width: 375px;
    }
  }
}
