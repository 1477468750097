.thanks-box {
  padding: 80px 0;
  text-align: center;

  &:before {
    background-position: 102% -40px;
    width: 100%;
    background-size: 320px auto;
  }

  &__title {
    color: $pink;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 800;
  }

  &__content {
    background-color: $white;
    padding: 65px 30px;
    margin: 0 auto;
    box-shadow: 0 0 20px -5px rgba(0,0,0,.3);
    max-width: 810px;
  }
}
