.faqs {
  padding: 40px 0;

  @include susy-media(desktop) {
    padding: 82px 0 105px;
  }

  &__item {
    @extend %clearfix;
    position: relative;

    @include susy-media(mobileTabletOnly) {
      margin-bottom: 30px;
    }

    &:first-child {
      .faqs__title {
        @include susy-media(desktop) {
          padding: 31px 15px 0 31px;
        }
      }

      .faqs__content {
        @include susy-media(desktop) {
          padding-top: 16px;
        }

        &:before {
          display: none;
        }
      }

      .faqs__content__inner {
        border-top: 0;
      }
    }

    &:last-child {
      .faqs__content  {
        @include susy-media(desktop) {
          padding-bottom: 45px;
        }
      }

      .faqs__content__inner {
        margin: 0;
      }
    }

    &:not(&:first-child),
    &:not(&:last-child) {
      .faqs__content {
        background-color: white;
      }
    }
  }

  &__title {
    line-height: 1.2;
    margin-bottom: 15px;

    @include susy-media(desktopOnly) {
      font-size: 30px;
    }

    @include susy-media(desktop) {
      margin-bottom: 0;
      padding: 15px 15px 0 31px;
      width: 29%;
      float: left;
    }
  }

  &__content {
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.4);
    position: relative;

    @include susy-media(desktop) {
      width: 71%;
      float: left;
      padding-left: 40px;
      padding-right: 40px;
    }

    &:before {
      @include susy-media(desktop) {
        content: "";
        height: 15px;
        width: 100%;
        position: absolute;
        top: -15px;
        background-color: $white;
        z-index: 2;
        left: 0;
      }
    }

    &__inner {
      border-bottom: 1px solid #dddee5;
      padding-bottom: 0;
      padding: 11px 0;

      &:last-child {
        @include susy-media(mobileTabletOnly) {
          border: 0;
        }
      }

      &:first-child {
        @include susy-media(desktop) {
          border-top: 1px solid #dddee5;
        }
      }
    }
  }

  &__question {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;
    transition: .5s;
    padding: 15px 25px 15px 0;
    cursor: pointer;

    @include susy-media(tablet) {
      font-size: 21px;
    }

    &:before {
      @extend %icons;
      content: "\e90b";
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -13px;
      font-size: 22px;
      transition: .5s;
      font-weight: bold;
      color: $text-color;
    }

    &.is-active {
      color: $blue-59;
      transition: .5s;

      &:before {
        content: "\e90d";
        transition: .5s;
      }
    }
  }

  &__answer {
    display: none;

    @include susy-media(desktop) {
      padding-right: 90px;
    }
  }
}
