.video-slider {
  .container-medium {
    @include susy-media(mobileOnly) {
      padding: 0;
    }
  }

  @include susy-media(mobileOnly) {
    background-color: $gray-f0;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 37px;
    padding: 0 20px;

    @media screen and (max-width: 29.9375em) {
      font-size: 28px;
      text-align: left;
    }
  }

  .field-item {
    width: 33%;
    float: left;
  }

  .slick-list {
    padding-bottom: 53px !important;
  }

  .slick-slide {
    transform: scale(.89);
    transition: .5s;
    position: relative;

    &:before {
      content: '';
      height: 50%;
      border-radius: 22%;
      bottom: 15px;
      left: 50%;
      width: 75%;
      height: 30px;
      transform: translateX(-50%);
      right: 0;
      position: absolute;
      box-shadow: 0 0 30px 10px #111;
    }
  }

  .slick-center {
    transform: scale(1.04);
    transition: .5s;

    .video-wrap__icon {
      opacity: 1;
    }

    .video-wrap__poster:before {
      opacity: 0;
      transition: .5s;
      visibility: hidden;
    }
  }

  .video-wrap__icon {
    opacity: 0;
    transition: .5s;
  }

  .video-wrap__poster {
    &:before {
      background: rgba(#1a1f51, .65);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      content: '';
      z-index: 2;
      opacity: 1;
      transition: .5s;
    }
  }

  &__inner {
    @include susy-media(desktop) {
      padding: 0 12px;
    }
  }

  .video-wrap {
    margin: 0;
  }

  .btn--play {
    background-size: 92px;

    @include susy-media(mobileOnly) {
      background-size: 37%;
    }
  }

  .slick-arrow {
    width: 33.33%;
    height: 100%;

    @include susy-media(mobileOnly) {
      width: 58px;
    }

    &:before {
      top: calc(50% - 27px);
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      font-size: 18px;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-color;

      @include susy-media(mobileOnly) {
        width: 25px;
        height: 25px;
        font-size: 8px;
      }
    }

    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
    }

    &.slick-prev {
      &:before {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
  }
}
