.pricing-box {
  padding: 25px 0;

  @include susy-media(tablet) {
    padding: 42px 0;
  }

  @include susy-media(desktop) {
    padding: 101px 0 98px;
  }

  &.bg--pink {
    .pricing-box__link .btn {
      @extend .btn--white;
    }
  }

  &__group {
    padding: 30px;
    text-align: center;
    padding: 0 0 30px 0;

    @include susy-media(desktop) {
      padding: 42px 20px 0 30px;
      width: 29%;
      float: left;
      text-align: left;
    }

    h2 {
      line-height: 1.5;

      @include susy-media(mobileTabletOnly) {
        font-size: 37px;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__link {
    margin-top: 23px;

    @include susy-media(desktop) {
      margin-top: 32px;
    }
  }

  &__content {
    text-align: center;
    background-color: $white;
    color: $text-color;
    padding: 20px;
    box-shadow: 0 0 20px -3px rgba(0, 0, 0, 0.4);

    @include susy-media(tablet) {
      padding: 30px;
    }

    @include susy-media(desktop) {
      padding: 37px 35px 32px;
      width: 71%;
      float: left;
    }

    .content-item {
      margin-bottom: 33px;

       > div {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    span {
      color: $pink;
    }

    h3 {
      margin-bottom: 30px;

      @include susy-media(mobileTabletOnly) {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 19px;
      }
    }

    h4 {
      font-weight: 800;
      margin-bottom: 7px;
      line-height: 1.2;

      @include susy-media(mobileTabletOnly) {
        br {
          display: none;
        }
      }
    }
  }
}
