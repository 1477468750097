.studio-timetable {
  padding: 30px 0 36px;
  @extend .bg--blue;
  text-align: center;

  @include susy-media(tablet) {
    padding: 81px 0 78px;
  }

  .section-title {
    color: $pink;
    margin-bottom: 30px;

    @include susy-media(mobileOnly) {
      font-size: 28px;
    }

    @include susy-media(tablet) {
      margin-bottom: 52px;
    }
  }

  .timetable-terms {
    margin-bottom: 36px;;

    @include susy-media(tablet) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 62px;
    }

    .timetable-term {
      padding-bottom: 33px;

      &:last-child {
          padding-bottom: 0;
      }

      @include susy-media(tablet) {
        padding: 0 15px
      }

      @include susy-media(desktopWide) {
        margin: 0 84px;
      }

      .coming-soon {
        cursor: auto;

        &:hover {
          background: #5975dc;
        }
      }
    }

    h4 {
      font-size: 21px;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 0;

      @include susy-media(tablet) {
        font-size: 28px;
      }
    }

    .btn {
      margin-top: 21px;

      @include susy-media(tablet) {
        margin-top: 25px;
      }
    }
  }

  .links {
    margin-top: 35px;

    @include susy-media(tablet) {
      margin-top: 59px;
    }

    a {
      margin: 0 12px;

      @include susy-media(mobileOnly) {
        display: block;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.bw-session__basics {
  @include susy-media(mobilePortraitOnly) {
    display: block;
  }
}

.bw-session__info {
  @include susy-media(mobilePortraitOnly) {
    min-width: inherit;
    margin: 0;
  }
}

.timetable-embeb {
  background-color: $white;
  padding: 24px 10px;
  color: $text-color;
  max-height: 510px;
  overflow: auto;

  @include susy-media(tablet) {
    padding: 32px;
    max-height: 1072px;
  }

  h3 {
    color: $blue-59;

    @include susy-media(mobileOnly) {
      margin-bottom: 3px;
    }
  }

  h4 {
    font-size: 21px;
    font-weight: 800;
    margin-bottom: 9px;
    line-height: 1.2;

    @include susy-media(tablet) {
      font-size: 28px;
    }
  }

  p {
    margin-bottom: 22px;

    @include susy-media(mobileOnly) {
      margin-bottom: 12px;
    }
  }

  .bw-widget__preheader,
  .bw--fullcal__field {
    display: none !important;
  }

  .bw-calendar,
  .bw-fullcal-button {
    display: block !important;
    text-align: center;
  }

  .bw-fullcal-button {
    font-size: 16px;
    margin: 0 auto;
  }

  .bw-widget--medium .bw-session__staff {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .bw-session__detail-link span,
  svg.bw-i-chevron {
    font-size: 16px;
    font-weight: 600;
    color: $pink;
  }

  .bw-header__filter-link {
    min-width: inherit;
  }

  .bw-widget {
    border: 1px solid $text-color;

    .bw-widget__header {
      padding: 16px 20px 14px 20px;

      @include susy-media(tablet) {
        padding: 24px 35px 14px 41px;
      }
    }

    .bw-header__title,
    .bw-header__filter-link-text,
    .bw-filters-icon {
      font-size: 14px;

      @include susy-media(tablet) {
        font-size: 16px;
      }
    }

    th.bw-calendar__heading {
      font-weight: 600;
      font-size: 12px;
    }

    td.bw-calendar__day {
      font-size: 12px;
      font-weight: 600;

      @include susy-media(tablet) {
        font-size: 16px;
      }
    }

    .bw-calendar__day.bw-calendar__day--current span {
      width: 30px;
      height: 30px;
      font-weight: 700!important;
      background-color: #e90081 !important;
    }

     td.bw-calendar__day {
       height: 45px;
     }

     .bw-fullcal-button {
       margin-top: 23px;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 7px;
      font-weight: 600!important;
      color: #e90081 !important;

      @include susy-media(tablet) {

      }
     }

     .hc_time {
        font-size: 16px;
        font-weight: 600;

        @include susy-media(tablet) {
          font-size: 20px;
        }
     }
  }

  .bw-widget--medium .bw-filters-icon, .bw-widget--large .bw-filters-icon {
    width: 20px;
    height: 20px;
  }

  .bw-widget--medium .bw-widget__date {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    padding: 16px 42px;

    @include susy-media(tablet) {
      font-size: 18px;
    }
  }

  .bw-widget--medium .bw-session {
    padding: 12px 42px 24px;
  }

  .bw-widget--spacious.bw-widget--medium .bw-session__time{
    float: left;
    margin-right: 30px;
  }

  .bw-widget--medium .bw-session__name {
    font-size: 19px;
    font-weight: 600;
  }

  .bw-widget--spacious.bw-widget--medium .bw-session__staff {
    font-size: 16px;
    color: #ccc;
    font-weight: 600;
  }

  .bw-session__location {
    display: none;
  }

  .bw-widget--spacious.bw-widget--medium .bw-session__detail-link {
    font-size: 16px;
    font-weight: 600;
    color: $pink!important;

    @include susy-media(tablet) {
      font-size: 18px;
    }
  }

  .bw-widget button.bw-widget__cta {
    color: $pink!important;
    text-align: right;
    font-size: 16px;
    font-weight: 600;

    @include susy-media(mobilePortraitOnly) {
      text-align: left;
      margin-top: 10px;
    }

    @include susy-media(tablet) {
      font-size: 18px;
    }
  }

  .bw-widget--medium .bw-filters__checkboxes {
    padding: 16px 26px 0;
  }

  .bw-widget--medium .bw-select {
    padding: 16px 42px;
  }
}

.bw-calendar {
  tbody {
    width: 100%;
    display: table;
  }
}

.message-coming-soon-term {
  @include opensans;
  text-transform: uppercase;
  position: fixed;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 23px 23px 30px;
  text-align: center;
  background-color: #f0f2f5;
  z-index: 999;
  font-weight: 700;
  font-size: 36px;
  color: $text-color;
  width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    position: absolute;
    top: -23px;
    right: -23px;
    background-color: $white;
    text-indent: -999rem;
    z-index: 999;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    cursor: pointer;

    &:before {
      @extend .icon-close:before;
      @extend %icons;
      color: $text-color;
      font-size: 25px;
      opacity: 1;
      text-indent: 0;
      position: absolute;
      height: 46px;
      width: 45px;
      top: 0;
      display: flex;
      left: 0;
      align-items: center;
      justify-content: center;
    }
  }
}

.is-show-comming-soon {
  .message-coming-soon-term {
    top: 50%;
    transition: .5s;
  }
}
