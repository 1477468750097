.popup-wrap {
  &.show {
    .popup-wrap__overlay {
      opacity: 1;
      z-index: 1000;
      visibility: visible;
      transition: .5s;
    }

    .popup-wrap__content {
      top: 50%;
      transition: .5s;
      padding: 0;
      max-width: 650px;
      background-color: #1a1f51;

      @include susy-media(mobileOnly) {
        top: 50%;
        max-width: 90vw;
      }
    }
  }

  .icon-close {
    position: absolute;
    right: 5px;
    top: -1px;
    cursor: pointer;
    color: #bbbdbf;
    font-size: 30px;

    @include susy-media(mobileOnly) {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 30px;

    @include susy-media(mobileOnly) {
      margin-bottom: 10px;
    }
  }

  .webform-component-markup h2,
  .webform-component-markup h1 {
    font-weight: 800;
    font-size: 50px;
    background-color: #ec008c;
    color: $white;
    padding: 24px 0;
    margin: 0 -10px;
    letter-spacing: -1px;

    @include susy-media(mobileOnly) {
      font-size: 30px;
      padding: 10px 0;
    }

    sub {
      font-size: 35px;
      vertical-align: baseline;

      @include susy-media(mobileOnly) {
        font-size: 25px;
      }
    }

    sup {
      font-size: 25px;
    }
  }

  &__content {
    position: relative;
    max-width: 450px;
    width: 100%;
    // height: 450px;
    background-color: $white;
    text-align: center;
    position: fixed;
    top: -150%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    padding: 38px 0 10px;
    transition: .5s;

    @include susy-media(mobileOnly) {
      padding: 25px 0 0;
      max-width: 300px;
      // height: 310px;
    }
  }

  form.webform-client-form {
    padding: 0;
  }

  fieldset {
    padding: 22px 99px 0;

    @include susy-media(mobileOnly) {
      padding: 10px 30px 0;
    }
  }

  .webform-component--subtitle {
    margin-bottom: 0;
  }

  form {
    padding: 22px 99px;

    @include susy-media(mobileOnly) {
      padding: 10px 30px;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black, .58);
    z-index: -1000;
    opacity: 0;
    transition: .5s;
    visibility: hidden;
  }

  .webform-component-checkboxes {
    margin-bottom: 15px;

    > label {
      text-align: left;
      width: 100%;
      padding: 0 20px;
      margin-bottom: 10px;

      @include susy-media(tablet) {
        width: 80%;
        padding: 0 3px;
      }
    }
  }

  .form-checkboxes {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;

    @include susy-media(mobileOnly) {
      padding: 0 15px;
      width: 100%;
    }

    .form-item {
      width: 50%;
      text-align: left;
      display: flex;
      align-items: center;
      font-size: 14px;

      @include susy-media(mobileOnly) {
        font-size: 12px;
      }

      label {
        margin: 0;
      }
    }
  }

  &__box-btn{
    padding: 38px 20px 10px;

    h2, .field-name-field-title-block .field-item{
      margin-bottom: 15px;
      color: #ffffff;
      line-height: 1;

      @media only screen and (min-width: $tablet){
        margin-bottom: 20px;
      }
    }

    h4, .field-name-field-subtitle-block .field-item{
      margin-bottom: 20px;
      text-transform: initial;
      font-size: 17px;
      color: #ffffff;
      line-height: 1.2;
    }

    ul{
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li{
        width: 100%;

        @media only screen and (min-width: 630px){
          width: calc(50% - 10px) !important;
          margin-bottom: 0 !important;
          padding: 0 !important;

          &:last-child{
            margin: auto;
          }
        }

        a{
          background-color: #ec008c;
          color: #ffffff;
          border-radius: 20px;
          font-size: 14px;
          cursor: pointer;
          outline: 0;
          font-weight: 600;
          padding: 7px 39px;
          display: inline-block;
          width: 100%;
          margin-bottom: 15px;
          line-height: 1.5;
        }
      }
    }

    .popup-mindbody-links{
      & > h2{
        display: none
      }

      .field-name-field-title-block, .field-name-field-subtitle-block{
        .field-label{
          display: none;
        }

        .field-item{
          font-weight: 800;
        }
      }

      .field-name-field-title-block{
        .field-item{
          font-size: 28px;

          @include susy-media(tablet) {
            font-size: 32px;
          }


          @include susy-media(desktop) {

            font-size: 37px;

          }

        }

      }


      .paragraphs-items-field-mindbody-link{

        .field-label, .introductory-list__title{

          display: none;

        }


        .introductory-list{

          padding: 0;

          display: block !important;

        }


        .introductory-list{

          ul{

            padding: 0;


            &::before{

              display: none;

            }


            li{

              @media only screen and (max-width: 629px){

                margin-bottom: 0;

                padding: 0;

              }

            }

          }

        }

      }

    }

  }
}

.popup-form {
  .captcha {
    .g-recaptcha {
      > div {
        margin: 0 auto;
      }
    }
  }

  .form-text,
  .form-email {
    border: 0;
    border-bottom: 1px solid $blue-1a;
    height: 40px;

    @include susy-media(mobileOnly) {
      height: 30px;
    }
  }

  .form-action,
  .form-actions {
    margin-top: 8px;
    display: inline-block;

    .form-submit {
      @extend .btn;
      @extend .btn--pink;
      @extend .btn--large;
    }
  }
}

.modal-resource-login-form {
  div.ctools-modal-content .modal-content,
  div.ctools-modal-content {
    max-width: 401px;
  }

  div.ctools-modal-content .modal-content {
    padding: 23px 23px 30px;
    text-align: center;
    background-color: $gray-f0;
    margin-top: -37%;

    h2 {
      font-size: 28px;
      color: $text-color;
      margin-bottom: 24px;
    }

    .messages {
      width: 100%;
      margin: 0 auto 10px;
      border-radius: 3px;
      font-size: 14px;
      padding: 10px;

      @include susy-media(mobileLandscape) {
        width: 257px;
      }
    }

    .messages.error ul {
      list-style-type: none;
    }

    .form-item {
      margin-bottom: 20px;

      input {
        font-size: 12px;
        border: 0;
        background: #fff;
        padding: 5px;
        text-align: center;
        height: 33px;
        width: 100%;

        @include susy-media(mobileLandscape) {
          width: 257px;
        }
      }
    }

    .form-submit {
      padding: 7px 45px;
      min-width: inherit;
    }
  }
}

.view-resource .attachment .ctools-use-modal {
  display: none;
}

.divas-hub-not-login {
  overflow: hidden;

  .backdrop-resource-login-form {
    background-color: transparent !important;
    display: none!important;
  }

  .region-content-wrap {
    position: relative;
  }

  .bg-light-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($white, .85);
    z-index: 99;
  }
}

//
.popup-select-studio,
.popup-login {
  position: fixed;
  max-width: 300px;
  width: 100%;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $gray-f0;
  padding: 30px 30px 20px;
  z-index: 103;

  @include susy-media(mobilePortraitOnly) {
    max-width: 270px;
  }

  .content {
    @include susy-media(mobileOnly) {
      overflow: auto;
    }
  }

  .close {
    position: absolute;
    top: -23px;
    right: -23px;
    background-color: $white;
    text-indent: -999rem;
    z-index: 999;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    cursor: pointer;

    &:before {
      @extend .icon-close:before;
      @extend %icons;
      color: $text-color;
      font-size: 25px;
      opacity: 1;
      text-indent: 0;
      position: absolute;
      height: 46px;
      width: 45px;
      top: 0;
      display: flex;
      left: 0;
      align-items: center;
      justify-content: center;
    }
  }

  @include susy-media(mobileLandscape) {
    padding: 20px 20px 5px;
    max-width: 420px;
  }

  @include susy-media(tablet) {
    padding: 20px 30px 10px;
    max-width: 650px;
  }

  h2 {
    font-size: 21px;

    @include susy-media(tablet) {
      font-size: 28px;
    }
  }

  .studio-group__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .studio-group__item {
      width: 50%;
      padding: 0 10px;
      margin-bottom: 20px;
      line-height: 0;

      @include susy-media(mobileLandscape) {
        width: 33.33%;
      }
    }

    a {
      width: 100%;
      background-color: $text-color;
      text-align: center;
      position: relative;
      display: inline-block;


      img {
        opacity: .3;
        width: 100%;
      }

      span {
        color: $white;
        font-weight: 800;
        font-size: 19px;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        line-height: 1.2;
        padding: 3px;

        @include susy-media(mobilePortraitOnly) {
          font-size: 16px;
        }

        @include susy-media(tablet) {
          font-size: 21px;
        }
      }
    }
  }
}

.popup-select-studio {
  &.grid-3top-4bottom {
    @include susy-media(tablet) {
      .studio-group__item {
        width: calc((100% - 62px) / 4);
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 0;
        overflow: hidden;
    
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: calc((100% - 42px) / 3);
          margin-right: 20px;
          margin-bottom: 20px;
        }
    
        &:nth-child(4n + 3) {
          margin-right: 0;
        }

        img {
          height: 100%;
          width: auto;
          max-width: inherit;
          min-height: 137px;
          left: 50%;
          position: relative;
          transform: translate(-50%, 0);
        }

        span {
          text-transform: uppercase;
          font-size: 17px;
        }
      }
    }
  }
}

.popup-login {
  .studio-login-group__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > healcode-widget {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;

      @include susy-media(mobileLandscape) {
        width: calc((100% - 20px) / 2);
        margin-right: 20px;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @include susy-media(tablet) {
        width: calc((100% - 40px) / 3);

        &:nth-child(2n) {
          margin-right: 20px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    a {
      @extend .btn;
      width: 100%;
      font-size: 13px;

      @include susy-media(mobileLandscape) {
        min-width: 180px;
      }
    }
  }
}

.page-node-21 #modal-content form  {
  .captcha {
    margin-bottom: 20px;

    @include susy-media(mobilePortraitOnly) {
      transform: scale(0.7);
      transform-origin: center left;
    }
  }
  .captcha >div >div {
    margin: 0 auto;
    @include susy-media(mobileLandscape) {
      width: 100% !important;
    }
  }
}

.webform-client-form-6 {
  .captcha {
    margin-bottom: 15px;

    @include susy-media(mobileOnly) {
      transform: scale(0.8);
      margin-bottom: 10px;
    }
  }
}
