.page-node-9 {
  @include susy-media(desktop) {
    .box-form {
      padding-top: 203px;
    }

    .box-media {
      padding-top: 51px;
    }

    .box-media--type3 {
      margin-bottom: 75px;
    }

    .box-media--type3 .box-media__wrap {
      bottom: -43px;
    }

    .box-media--type3 .box-media__content {
      padding: 15px 10px 0 36px;
    }
  }
  .grid-text__wrap {
    h5 {
      @extend .title--tiny;
    }

    .grid-text__item {
      @include susy-media(tablet) {
        padding-top: 43px;
      }
    }
  }
}

// Faq.
.page-node-28 {
  main {
    background-color: $gray-f0;
  }
}

.page-node-31 {
  .box-hero__title {
    @include susy-media(desktopWide) {
      max-width: 420px;
    }
  }
}

// Page not found.
.page-node-175 {
  .action-links {
    @extend .container;
  }
}

// Page studio.
.page-node-5 {
  .region-content {
    a.btn {
      @include susy-media(mobileOnly) {
        max-width: 255px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    .box-media--image .box-media__link {
      text-align: center;
      margin-top: 20px;
    }
  }

  .content-bottom__item.first {
    @include susy-media(mobileOnly) {
      width: 100%;
      padding: 0;
    }
  }
}

.page-node-4 {
  .box-article {
    display: none;
  }
}
