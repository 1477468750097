.front {
  .header {
    background-color: transparent;
    box-shadow: none;

    &.is-show {
      background-color: $white;

      .menu-wrap {
        i {
          border-color: $text-color;
        }

        a {
          color: $text-color;
        }
      }
    }

    ul a,
    .nolink {
      color: $white;
    }

    &__space {
      height: 0;
    }
  }

  &.navbar-horizontal {
    .header {
      top: 39px;
    }
  }

  .header__wrap {
    .left-menu .expanded,
    .right-menu .expanded {
      &:hover > .nolink,
      &.is-active > .nolink,
      > ul {
        background: rgba($blue-1a, .5);
        color: $white;
      }
    }

    .logo {
      transition: .3s;

      .pink {
        display: none;
      }

      .white {
        display: block;
      }
    }
  }

  .menu-icon i {
    border-color: $white;
  }

  .group-logo .menu-wrap a {
    color: $white;
  }
}

.sticky {
  @include susy-media(desktop) {
    &.header {
      background: $white;
      box-shadow: 0 0 10px -5px $black;
      transition: .3s;

      ul a,
      .nolink {
        color: $text-color;
      }

      .group-logo .menu-wrap a {
        color: $text-color !important;
      }

      .menu-icon i {
        border-color: $text-color !important;
      }
    }

    & + .header__space {
      transition: .5s;
      height: 113px;
    }

    .header__wrap {
      .logo {
        transition: .3s;

        @include susy-media(desktop) {
          width: 215px;
        }

        @include susy-media(desktopWide) {
          width: 280px;
        }

        .pink {
          display: block;
        }

        .white {
          display: none;
        }
      }

      .left-menu,
      .right-menu {
        margin-bottom: 0;

        .expanded {
          &:hover > .nolink,
          &.is-active > .nolink,
          > ul {
            background: $blue-1a;
            color: $white;
          }
        }
      }
    }
  }
}

.header {
  padding: 7px 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: $white;
  transition: .3s;
  box-shadow: 0 0 10px -5px $black;

  &__space {
    transition: .3s;
    height: 78px;

    @include susy-media(desktop) {
      height: 120px;
    }

    @include susy-media(desktopWide) {
      height: 150px;
    }
  }

  &__wrap {
    margin: 0 auto;
    max-width: 1440px;
  }

  &__top {
    text-align: right;

    @include susy-media(desktopWide) {
      margin-bottom: -22px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  ul {
    list-style: none;

    li {
      text-transform: uppercase;
      list-style: none;
      margin-left: 0;
      padding: 0;

      ul li {
        @include susy-media(desktop) {
          text-transform: capitalize;
        }
      }
    }

    a {
      color: $text-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &__mobile {
    padding: 0 22px 0 12px;

    @include susy-media(desktop) {
      display: none;
    }

    .logo {
      line-height: 0;
    }
  }
}

.header__wrap {
  display: none;

  @include susy-media(desktop) {
    display: block;
  }

  .logo {
    padding: 0 20px;
    transition: .3s;

    @include susy-media(desktop) {
      padding: 0 20px;
      width: 215px;
    }

    @include susy-media(desktopWide) {
      padding: 0 40px;
      width: 400px;
    }

    img {
      width: 100%;
      height: auto;
    }

    .white {
      display: none;
    }
  }

  .secondary-menu {
    display: inline-block;

    ul {
      margin: 0;
      display: flex;

      li {
        font-size: 14px;
      }

      a {
        padding: 0 10px;

        @include susy-media(desktopWide) {
          padding-left: 22px;
          padding-right: 20px;
        }

        &:before {
          font-size: 13px;
          padding-right: 4px;
        }
      }
    }
  }

  .left-menu,
  .right-menu {
    width: 50%;

    @include susy-media(desktopWide) {
      margin-bottom: 20px;
    }

    > ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      > li {
        font-size: 14px;
        float: left;
        padding: 0;
        margin: 0;
      }

      a,
      .nolink {
        padding: 13px 4px 14px;

        @include susy-media(desktopWide) {
          padding: 13px 10px 14px;
        }

        @include susy-media(desktopSuperwide) {
          padding: 13px 16px 14px;
        }
      }
    }

    .expanded {
      position: relative;

      &:hover {
        > ul {
          @include susy-media(desktop) {
            display: block !important;
          }
        }

        > .nolink {
          background-color: $blue-1a;
          color: $white;
        }
      }

      &.is-active {
        > a,
        > .nolink {
          background-color: $blue-1a;
          color: $white;
        }
      }

      > a,
      > .nolink {
        position: relative;

        &:after {
          @extend .icon-arrow-down:before;
          @extend %icons;
          font-size: 18px;
          position: relative;
          top: 2px;
          right: -3px;
        }
      }

      > ul {
        display: none;
        position: absolute;
        top: 35px;
        left: 0;
        background-color: $blue-1a;
        margin: 0;
        padding: 8px 0 15px;
        width: 200px;

        a,
        span {
          color: $white;
          padding: 7px 22px;
          display: inline-block;
          width: 100%;
          transition: .5s;

          &.active,
          &:hover {
            font-weight: 600;
            transition: .5s;
          }
        }
      }
    }
  }
}

.menu-mobile {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $blue-1a;
  top: 78px;
  padding: 36px 28px;
  display: none;
  overflow: auto;

  @include susy-media(mobilePortraitOnly) {
    padding: 20px 10px;
  }

  nav > ul {
    padding: 0;
    margin-bottom: 0;
    font-weight: 800;

    > li > a,
    .nolink {
      font-size: 28px;
      text-transform: uppercase;
      font-weight: 800;
      padding: 13px 10px;
      display: inline-block;
      width: 100%;
      color: $white !important;
      border-bottom: 1px solid #53577d;
      position: relative;

      @include susy-media(mobilePortraitOnly) {
        font-size: 24px;
      }

      &.icon-home {
        display: none;
      }

      &:before {
        @include susy-media(mobileTabletOnly) {
          position: absolute;
          right: 0;
          top: 22px;
        }
      }
    }
  }

  .expanded {
    > a,
    > .nolink {
      position: relative;

      &:after {
        font-family: "poledivas";
        position: absolute;
        content: "\e905";
        right: -3px;
        top: 13px;
        transition: .3s;
      }

      &.is-active {
        color: $pink !important;

        &:after {
          transform: rotate(180deg);
          transition: .3s;
        }
      }
    }

    > ul {
      display: none;
      padding: 10px;
      margin: 0;

      @include susy-media(mobileTabletOnly) {
        border-bottom: 1px solid #53577d;
      }

      a {
        display: inline-block;
        width: 100%;
        font-size: 21px;
        padding: 4.5px 0;
        color: $white !important;
      }
    }
  }

  .secondary-menu {
    background-color: #ec008c;
    position: relative;

    &:before {
      content: '';
      background-color: #ec008c;
      position: absolute;
      left: -28px;
      right: -28px;
      top: 0;
      bottom: 0;

      @include susy-media(mobilePortraitOnly) {
        left: -10px;
        right: -10px;
      }
    }

    ul {
      position: relative;
      z-index: 1;
    }

    li a {
      border-bottom: 1px solid #f140a9;
    }
  }

  .right-menu li:last-child a {
    border-bottom: 0;
  }
}

.group-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0;

  .menu-wrap a {
    font-size: 24px;
    color: #182759;
    margin-right: 34px;
    line-height: 0;

    @include susy-media(desktop) {

    }
  }
}

.menu-icon {
  cursor: pointer;
  display: inline-block;
  height: 16px;
  position: relative;
  width: 30px;
  z-index: 99;
  margin-bottom: 4px;

  i {
    border-bottom: 3px solid #182759;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: .5s;
    width: 100%;

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 100%;
      opacity: 1;
    }
  }
}

.is-show {
  .menu-icon {
    i {
      &:nth-child(1) {
        transform: rotate(-45deg);
        top: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        opacity: 0;
      }
    }
  }
}

.navbar-horizontal {
  .header {
    top: 39px;
  }
}
