.box-hero {
  color: $white;
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  background: rgb(42,29,85); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(42,29,85,1) 0%, rgba(226,2,137,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(42,29,85,1) 0%,rgba(226,2,137,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(42,29,85,1) 0%,rgba(226,2,137,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  z-index: 100;

  @include susy-media(mobileOnly) {
    padding: 22px 0;
  }

  .container {
    @include susy-media(mobileTabletOnly) {
      width: 100%;
      margin: 0;
      padding: 0 20px;
    }
  }

  &.height-large {
    min-height: 182px;

    @include susy-media(tablet) {
      min-height: 275px;
    }

    @include susy-media(desktop) {
      height: 275px;
    }

    .box-hero__content {
      padding-top: 12px;

      @include susy-media(tablet) {
        padding-top: 0;
        padding-bottom: 12px;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    text-align: center;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      @include susy-media(desktop) {
        width: 100%;
      }
    }

    &:before {
      left: 0;
      background: -moz-linear-gradient(45deg, rgba(26,31,81,1) 0%, rgba(111,114,145,0) 50%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgba(26,31,81,1) 0%,rgba(111,114,145,0) 50%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgba(26,31,81,1) 0%,rgba(111,114,145,0) 50%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &:after {
      right: 0;
      background: -moz-linear-gradient(45deg, rgba(236,0,140,1) 0%, rgba(243,95,183,0) 50%, rgba(255,255,255,0) 99%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgba(236,0,140,1) 0%,rgba(243,95,183,0) 50%,rgba(255,255,255,0) 99%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgba(236,0,140,1) 0%,rgba(243,95,183,0) 50%,rgba(255,255,255,0) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      transform: scaleX(-1);
    }

    img {
      width: auto;
      height: 101%;
      position: absolute;
      max-width: inherit;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include susy-media(1360px) {
        width: 101.5%;
        height: auto;
        min-width: 101%;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 0;

    @include susy-media(desktopWide) {
      max-width: 50%;
    }
  }


  div.box-hero__list {
    @include susy-media(tablet) {
      margin-top: 16px;
    }
  }

  ul.box-hero__list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    text-transform: capitalize;

    @include susy-media(desktop) {
      flex-wrap: wrap;
    }

    a {
      margin-top: 13px;
      margin-right: 10px;
      min-width: 130px;
      padding: 7px 10px;

      @include susy-media(mobileOnly) {
        font-size: 16px;
        min-width: 150px;
        padding: 8px 10px;
      }

      @include susy-media(mobilePortraitOnly) {
        font-size: 14px;
        padding: 8px;
      }

      @include susy-media(tablet) {
        margin-top: 10px;
      }

      &.active {
        @extend .btn--pink;
      }
    }
  }

  &.with-link {
    padding: 30px 0;

    @include susy-media(mobileOnly) {
      padding: 30px 0 17px;
      align-items: flex-end;
    }

    .box-hero__content {
      padding-top: 0;

      @include susy-media(tablet) {
        padding-bottom: 0;
        padding-top: 47px;
      }
    }

    div.box-hero__list {
      @include susy-media(mobileOnly) {
        position: relative;
        bottom: -37px;
        overflow: hidden;
        width: calc(100% + 20px);

        ul {
          overflow: auto;
        }
      }
    }

    &.is-fixed {
      div.box-hero__list {
        position: fixed;
        top: 80px;
        margin: 0;
        left: 0;
        right: 0;
        bottom: inherit;
        padding: 0 20px;
        z-index: 9;
        background: rgb(42,29,85); /* Old browsers */
        background: -moz-linear-gradient(left, rgba(42,29,85,1) 0%, rgba(226,2,137,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(42,29,85,1) 0%,rgba(226,2,137,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(42,29,85,1) 0%,rgba(226,2,137,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        @include susy-media(desktop) {
          top: 121px;
          padding: 0 30px;
        }

        @include susy-media(desktopWide) {
          top: 109px;

          ul {
            max-width: 1400px;
            margin: 0 auto;
          }
        }

        a {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
