.banner-slider {
  &__item {
    @include susy-media(mobileOnly) {
      height: 602px;
      // height: auto;
      position: relative;
      overflow: hidden;
    }

    @include susy-media(mobilePortraitOnly) {
      height: 500px;
    }
  }

  &__image {
    img {
      width: 100%;
      height: auto;

      @include susy-media(mobileOnly) {
        position: absolute;
        height: 100%;
        max-width: inherit;
        width: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__content {
    @extend .text--white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 9;
    padding: 30px 20px;

    @include susy-media(tablet) {
      padding: 60px 20px 0 20px;
      align-items: center;
    }

    @include susy-media(desktop) {
      padding: 130px 20px 0 20px;
    }

    @include susy-media(desktopWide) {
      padding: 142px 20px 0 20px;
    }

    a {
      min-width: inherit;
    }
  }

  &__label {
    color:$white;
    @extend .title--small-large;

    @include susy-media(mobileOnly) {
      margin-bottom: 5px;
    }
  }

  &__title {
    @extend .title--largest;
  }

  .slick-dots {
    position: absolute;
    bottom: 7px;
    left: 0;
    right: 0;
    z-index: 10;

    @include susy-media(mobileOnly) {
      bottom: -50px;
    }
  }
}
