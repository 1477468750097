.wide-box {
  text-align: center;
  padding: 15px 0 29px;

  @include susy-media(tablet) {
    padding: 40px 0 20px;
  }

  @include susy-media(desktop) {
    padding: 63px 0;
  }

  &__title {
    margin-bottom: 11px;
  }

  p {
    margin-bottom: 9px;
  }

  a {
    text-decoration: underline;
  }

  b {
    font-weight: 600;
  }
}
