#modalContent.modal-default {
  @include susy-media(tablet) {
    top: 50px!important;
    left: 50%!important;
    position: absolute!important;
    transform: translate(-50%, 0);
  }

  @include susy-media(mobileOnly) {
    top: 0!important;
    left: 0!important;
    right: 0!important;
    bottom: 0!important;
    position: fixed!important;
  }

  div.ctools-modal-content .popups-close a{
    @include susy-media(mobileOnly) {
      top: 0;
      right: 0;
    }
  }
  div.ctools-modal-content {
    @include susy-media(mobileOnly) {
      width: 100%!important;
      overflow: auto;
    }
  }
}

#modalContent.modal-resource-login-form {
  top: 50%!important;
  left: 50%!important;
  position: absolute!important;
  transform: translate(-50%, 0);
  z-index: 100 !important;

  div.ctools-modal-content .popups-close a{
    top: -23px;
    right: -23px;
    display: none;
  }
}

div.ctools-modal-content {
  padding: 0;
  border: 0;
  margin: 0;
  position: relative;
  height: 100%!important;

  @include susy-media(tablet) {
    max-width: 922px;
    max-height: 884px;
  }

  .modal-title {
    display: none;
  }

  .modal-header {
    padding: 0;
    background: none;
  }

  .popups-close a {
    position: absolute;
    top: -23px;
    right: -23px;
    background-color: $white;
    text-indent: -999rem;
    z-index: 999;
    width: 46px;
    height: 46px;
    border-radius: 100%;

    &:before {
      @extend .icon-close:before;
      @extend %icons;
      color: $text-color;
      font-size: 25px;
      opacity: 1;
      text-indent: 0;
      position: absolute;
      height: 46px;
      width: 45px;
      top: 0;
      display: flex;
      left: 0;
      align-items: center;
      justify-content: center;
    }

    // &:after {
    //   position: fixed;
    //   content: '';
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   z-index: 1;
    // }
  }

  .modal-content {
    max-width: 922px;
    max-height: 884px;
    height: 100%!important;
    width: 100%!important;
    padding: 20px;
    // overflow: hidden;

    @include susy-media(desktop) {
      padding: 30px;
    }
  }

  .video-wrap__poster img {
    width: 100%;
    height: auto;
    max-width: 860px;
  }

  .video-wrap {
    padding-bottom: 66.25%;
    margin-bottom: 25px;
  }

  .slider-media-nav {
    .video-wrap {
      padding-bottom: 0;
      margin-bottom: 0;
      height: auto;
    }
  }
}

.resource-gallery {
  position: relative;
  z-index: 2;
}

.slider-media {
  margin-bottom: 14px;

  &__title {
    font-size: 28px;
    color: $text-color;
    margin-bottom: 18px;
  }
}

.slider-video__item {
  img {
    margin-bottom: 25px;
  }
}

.slider-media__description {
  color: #1a1f51;
}

.slider-media-nav {
  // padding: 0 23px;
  // margin: 0 -10px;
  display: flex;
  align-items: center;

  .slick-arrow {
    height: auto;
    min-width: 20px;
    position: relative;
    top: 0;
    transform: translate(0, 0);
    text-indent: 0;
    font-size: 30px;
    text-align: center;
    color: $blue-59;

    @include susy-media(mobileLandscape) {
      font-size: 50px;
    }

    &:hover {
      color: $pink;
    }
  }

  .slick-prev {
    margin-right: 7px;
  }

  .slick-next {
    margin-left: 7px;
  }

  .slick-list {
    width: calc(100% - 40px);
  }

  &__item {
    padding: 0 7px;
    cursor: pointer;
  }


}

.slider-media-control {
  span {
    position: absolute;
    bottom: 0;
    font-size: 45px;
    font-weight: 700;
    color: $text-color;

    &.js-prev {
      left: 0;
    }

    &.js-next {
      right: 0;
    }
  }
}
