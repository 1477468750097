.grid-address {
  @include susy-media(mobileTabletOnly) {
    margin-bottom: 20px;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    color: $white;
    overflow: hidden;
    min-height: 300px;

    @include susy-media(tabletOnly) {
      margin-right: 15px;
      margin-bottom: 15px;
      width: calc((100% - 15px) / 2);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include susy-media(desktop) {
      margin-right: 30px;
      margin-bottom: 30px;
      width: calc((100% - 62px) / 3);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 15px;
    font-size: 12px;

    @include susy-media(mobileLandscape) {
      padding: 25px 31px 14px;
    }

    @include susy-media(tablet) {
      padding: 25px 20px 14px;
    }

    @include susy-media(desktop) {
      padding: 23px 28px 12px;
    }
  }

  h4 {
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 16px;
  }

  &__title {
    margin-bottom: 19px;
    font-size: 28px;

    @include susy-media(tablet) {
      font-size: 37px;
    }

    @include susy-media(desktop) {
      font-size: 28px;
    }

    @include susy-media(desktopWide) {
      font-size: 37px;
    }
  }

  &__image {
    background-color: $text-color;
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      opacity: .18;
      min-width: 100%;
      min-height: 100%;
      max-width: inherit;
      height: 100%;
      width: auto;
    }
  }

  &__address {
    margin-bottom: 9px;
  }

  &__icon {
    position: absolute;
    right: 20px;
    top: 15px;

    span {
      padding-top: 49px;
      font-size: 12px;
      display: inline-block;
      width: 25px;
      height: 25px;
      font-weight: 600;
      text-align: center;
      background-position: top center;
      margin-right: 9px;
      line-height: 1.2;

      @include susy-media(mobileLandscape) {
        width: 52px;
        height: 52px;
      }

      @include susy-media(tablet) {
        width: 38px;
        height: 38px;
      }

      @include susy-media(desktopWide) {
        width: 52px;
        height: 52px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .aerial-divas {
      background: url(../images/aerial-divas.png) no-repeat scroll 0 0 transparent;
      background-size: 100% auto;
    }

    .pole-divas {
      background: url(../images/pole-divas.png) no-repeat scroll 0 0 transparent;
      background-size: 100% auto;
    }
  }
}


.grid-3top-4bottom {
  .grid-address__wrap,
  .grid-image__wrap {
    @media(min-width: 1024px) {
      justify-content: center;
    }
  }

  .grid-address__item,
  .grid-image__item {
    @media(min-width: 1024px) {
      width: calc((100% - 62px) / 4);
      margin-right: 20px;
      margin-bottom: 20px;
      overflow: hidden;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: calc((100% - 42px) / 3);
        margin-right: 20px;
        margin-bottom: 20px;
      }

      &:nth-child(4n + 3) {
        margin-right: 0;
      }
    }
  }

  &.grid-image--6cols {
    .grid-image__type span {
      @media(min-width: 768px) { 
        width: 52px;
        height: 52px;
      }
    }

    .grid-image__title {
      @media(min-width: 1440px) {
        font-size: 32px
      }
    }
  }

  // grid address.
  .grid-address__content {
    @media(min-width: 1024px) and (max-width: 1439px) {
      padding: 15px;
    }
  }

  .grid-address__icon {
    @media(min-width: 1024px) and (max-width: 1439px) {
      right: 12px;
    }

    span {
      @media(min-width: 1024px) and (max-width: 1439px) {
        width: 35px;
        height: 35px;
      }
    }
  }

  .grid-address__title {
    font-size: 24px;
  }

  .grid-image__image img {
    @media(min-width: 1024px) {
      width: auto;
      height: 100%;
      min-height: 254px;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      max-width: inherit;
    }

    @media(min-width: 1340px) {
      min-height: 327px;
    }

    @media(min-width: 1921px) {
      width: 100%;
      height: auto;
    }
  }

  // Grid image.
  .grid-image__title {
    @media(min-width: 1024px) and (max-width: 1300px) {
      font-size: 26px;
    }

    @media(min-width: 1301px) and (max-width: 1439px) {
      font-size: 30px;
    }
  }

  @media(min-width: 1024px) and (max-width: 1439px) {
    .grid-image__content {
      padding: 15px;
    }

    .grid-image__link {
      a {
        right: 10px;
      }
    }
  }

  .grid-image__type {
    @media(min-width: 1024px) and (max-width: 1439px) {
      bottom: 15px;
      left: 10px;
    }

    span {
      @media(min-width: 1024px) and (max-width: 1439px) {
        width: 32px;
        height: 32px;
      }

      @media(min-width: 1301px) and (max-width: 1439px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  
}