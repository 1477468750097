.footer {
  background-color: $gray-e1;
  padding: rem(26px 0 18px);
  color: $gray-96;
  position: relative;

  @include susy-media(mobileOnly) {
    background-color: $gray-f0;
  }

  &__wrap {
    display: flex;

    @include susy-media(mobileOnly) {
      flex-wrap: wrap;
    }

    @include susy-media(tabletOnly) {
      justify-content: space-around;
    }
  }
}

.footer-logo {
  @include susy-media(mobileOnly) {
    display: none;
  }

  @include susy-media(tabletOnly) {
    padding-right: rem(50px);

    img {
      width: 100%;
      height: auto;
    }
  }

  @include susy-media(desktop) {
    padding-right: rem(83px);

    img {
      min-width: 240px;
    }
  }
}

.footer-menu {
  @include susy-media(mobileOnly) {
    order: 2;
    width: 100%;
  }

  @include susy-media(tablet) {
    padding-right: rem(30px);
  }

  @include susy-media(desktop) {
    min-width: 340px;
  }

  ul {
    padding: 0;
    margin: 0;

    &.menu li {
      list-style: none;
    }
  }

  ul li {
    list-style: none;
    font-size: rem(12px);
    margin-bottom: rem(5px);
    margin-top: 0;
    padding: 0;

    &:last-child {
      margin-bottom: 0;

      @include susy-media(mobileOnly) {
        margin-bottom: 15px;
      }
    }

    a {
      color: $gray-96;
      display: inline-block;
      width: 100%;

      @include susy-media(mobileOnly) {
        border-top: 1px solid $gray-e1;
        padding: 11px 0;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.footer-info {
  @include susy-media(mobileOnly) {
    order: 1;
    width: 100%;
  }

  @include susy-media(desktop) {
    min-width: 242px;
  }

  a {
    color: $gray-96;

    &:hover {
      color: $primary-color;
    }
  }
}

.zipplay {
  margin-bottom: rem(15px);
}

.box-social {
  margin-bottom: rem(20px);
  display: flex;

  @include susy-media(mobileOnly) {
    margin-bottom: rem(33px);
    width: 100%;
  }

  ul {
    display: flex;
    padding: 0;
    margin: 0;

    @include susy-media(mobileOnly) {
      justify-content: space-between;
      width: 100%;
    }

    li {
      list-style: none;
      margin: 0;

      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }

  a {
    display: inline-block;
    font-size: 0;
    visibility: hidden;
    color: $gray-96;
    margin-right: rem(19px);

    @include susy-media(mobileLandscapeOnly) {
      margin-right: rem(33px);
    }

    &:before {
      font-size: rem(35px);
      visibility: visible;

      @include susy-media(mobileLandscapeOnly) {
        font-size: rem(58px);
      }
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.copyright {
  font-size: rem(12px);

  @include susy-media(mobileOnly) {
    position: absolute;
    bottom: 7px;
  }
}
