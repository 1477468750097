.box-5050 {
  padding: 30px 0;

  @include susy-media(mobileOnly) {
    background-color: $gray-f0;
  }

  &__inner {
    @include susy-media(desktop) {
      padding: 0 35px;
    }

    @include susy-media(mobileLandscape) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__item {
    padding: 22px 0;

    @include susy-media(mobileOnly) {
      padding: 31px 0;
    }
  }

  &__subtitle {
    @extend .title--tiny;
  }

  &__title {
    font-size: 37px;
    margin-bottom: 25px;

    @include susy-media(mobilePortraitOnly) {
      font-size: 28px;
    }

    @include susy-media(mobileOnly) {
      margin-top: -4px;
      margin-bottom: 23px;
    }

    @include susy-media(mobilePortraitOnly) {
      margin: 0 0 22px 0;
    }
  }

  &__content {
    @include susy-media(tablet) {
      width: 70%;
      padding-right: 30px;
    }

    @include susy-media(desktop) {
      padding-right: 80px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    min-width: 180px;

    @include susy-media(mobileOnly) {
      margin-top: 35px;
    }


    @include susy-media(tablet) {
      text-align: right;
      width: 30%;
      min-width: 250px;
    }

    @include susy-media(desktop) {
      min-width: 345px;
    }
  }

  &__image-mobile {
    @include susy-media(tablet) {
      display: none;
    }
  }

  &--type1 {
    background-color: $white;

    @include susy-media(mobileOnly) {
      padding: 40px 0 35px;
    }

    padding-bottom: 50px;

    .box-5050 {
      &__image-mobile {
        text-align: center;
      }

      &__title {
        font-size: 37px;
        margin-bottom: 20px;

        @include susy-media(mobilePortraitOnly) {
          font-size: 28px;
        }

        @include susy-media(mobileOnly) {
          margin-top: 2px;
          margin-right: 0;
        }
      }

      &__item {
        padding: 10px 0;

        &:nth-child(2n){
          .box-5050__content {
            order: 2;
            padding-right: 0;
          }

          .box-5050__image {
            order: 1;
            margin-right: 30px;

            @include susy-media(desktop) {
              margin-right: 75px;
            }
          }
        }
      }

      &__image {
        @include susy-media(mobileOnly) {
          display: none;
        }
      }
    }
  }

  &--type2 {
    padding: 21px 0 0;

    .box-5050__item {
      padding-bottom: 24px;

      @include susy-media(tablet) {
        padding-bottom: 19px;
      }
    }

    .box-5050__image {
      @include susy-media(mobileOnly) {
        display: none;
      }
    }
  }

  &--type3 {
    padding: 30px 0 48px;

    @include susy-media(mobileOnly) {
      padding: 0 0 28px;
    }

    .box-5050 {
      &__item {
        padding: 10px 0;

        &:nth-child(1) {
          .box-5050__image {
            @include susy-media(mobileOnly) {
              display: none;
            }
          }
        }

        &:nth-child(2n + 1){
          .box-5050__content {
            order: 2;
            padding-right: 0;
          }

          .box-5050__image {
            order: 1;
            margin-right: 30px;

            @include susy-media(desktop) {
              margin-right: 75px;
            }
          }
        }
      }
    }
  }
}
