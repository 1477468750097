.grid-facebook-image {
  padding-top: 18px;

  @include susy-media(tablet) {
    max-width: 300px;
    padding-top: 23px;
  }

  &__title {
    position: relative;

    a {
      color: $pink;
      line-height: 0;
      font-size: 28px;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    span {
      font-size: 24px;
    }
  }

  &__wrap {
    margin: 0 -5px;

    a {
      width: 50%;
      float: left;
      line-height: 0;
      padding: 0 5px;
      margin-bottom: 10px;
      overflow: hidden;

      // @include susy-media(mobileLandscape) {
      //   width: 115px;
      //   height: 107px;
      // }

      @include susy-media(tablet) {
        width: 142px;
        height: 133px;
      }
    }

    img {
      width: 100%;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: inherit;
    }
  }

  iframe {
    width: 100%;
    height: auto;
  }
}

.facebook-like-box {
  overflow: hidden;

  @include susy-media(mobileLandscapeOnly) {
    height: 393px;
  }

  @include susy-media(600px) {
    height: auto;
  }

  @include susy-media(mobileOnly) {
    width: 100%;

    .fb_iframe_widget {
      width: 100%;

      * {
        width: 100% !important;
      }
    }
  }

  iframe {
    width: 100%!important;
  }
}
