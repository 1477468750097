.list-link {
  padding: 19px 15px 22px;
  margin-bottom: 20px;

  @include susy-media(desktop) {
    margin-bottom: 15px;
    max-width: 279px;
  }

  &__title,
  > h2 {
    background: url('../images/news.png') no-repeat scroll 0px 3px transparent;
    padding-left: 42px;
    margin-bottom: 34px;
    margin-right: -8px;
    font-size: 37px;

    @include susy-media(mobilePortraitOnly) {
      font-size: 28px;
      background-size: 25px auto !important;
    }
  }

  &__item {
    margin-bottom: 16px;
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(#fff, .2);
    font-weight: 600;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1.2;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }
  }

  &.bg--pink {
    .list-link__title,
    > h2 {
      background: url('../images/evens.png') no-repeat scroll 0px 3px transparent;
    }
  }
}

.panel-separator + .list-link {
  @include susy-media(mobileOnly) {
    margin-bottom: 10px;
  }
}
