.studio-list {
  position: relative;
  z-index: 2;
}
.grid-image {
  background-color: #1a1f51;
  position: relative;
  // z-index: 3;

  @include susy-media(mobileOnly) {
    margin-bottom: 7px;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    @include susy-media(mobileTabletOnly) {
      font-size: 19px;
    }

    @include susy-media(desktop) {
      font-size: 30px;
    }

    @include susy-media(desktopWide) {
      font-size: 37px;
    }
  }

  &__item {
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    @include susy-media(mobileLandscape) {
      width: calc((100% - 15px) / 2);
      margin-right: 15px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include susy-media(tablet) {
      width: calc((100% - 40px) / 3);
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(2n) {
        margin-right: 20px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @include susy-media(desktop) {
      width: calc((100% - 62px) / 3);
      margin-right: 30px;
      margin-bottom: 30px;

      &:nth-child(2n) {
        margin-right: 30px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  &__image {
    line-height: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: $white;
    padding: 13px 14px;

    @include susy-media(desktop) {
      padding: 23px 20px;
    }

    @include susy-media(desktopWide) {
      padding: 23px 28px;
    }
  }

  &__type {
    position: absolute;
    left: 10px;
    bottom: 10px;

    @include susy-media(desktop) {
      bottom: 20px;
      left: 20px;
    }
  }

  &__link {
    a {
      z-index: 2;
    }
  }

  .btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
    min-width: inherit;
    padding: 7px 25px;

    @include susy-media(mobileOnly) {
      display: none;
      bottom: 5px;
    }

    @include susy-media(desktop) {
      right: 21px;
      bottom: 23px;
    }
  }

  &--type1 {
    margin-bottom: -73px;

    @include susy-media(mobileOnly) {
      // margin-bottom: -50px;
      margin-bottom: -35px;
    }

    .grid-image__wrap {
       @extend .space-top-over;
    }
  }


  &--6cols {
    padding: 33px 0 5px;

    @include susy-media(mobileOnly) {
      padding: 33px 0 35px;
    }

    .section-title {
      color: $white;
      margin-bottom: 32px;
      text-align: center;
    }

    .grid-image__item {
      @include susy-media(desktopWide) {
        width: calc((100% - 75px) / 6);
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .grid-image__link {
      display: none;
    }

    .grid-image__title {
      @include susy-media(desktopWide) {
        font-size: 21px;
      }
    }

    .grid-image__content {
      padding: 12px 14px;
    }

    .grid-image__type {
      bottom: 5px;
      left: 10px;

      span {
        width: 26px;
        height: 26px;
      }
    }

    .btn {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  &--offer {
    background-color: $white;
    margin: 30px 0 15px;

    .grid-image__link a {
      @extend .btn;
      @extend .btn--white;
      min-width: inherit;
      padding: 7px 20px;
      position: absolute;
      bottom: 12px;
      left: 12px;
    }

    .grid-image__type {
      right: 9px;
      left: unset;
      bottom: 5px;

      > span {
        width: 27px;
        height: 27px;
        margin-right: 4px;
      }
    }

    .grid-image__title {
      font-size: 24px;
      text-align: center;
      margin: 0;
      margin-top: 9px;
    }

    .grid-image__content {
      z-index: 2;
    }

    .grid-image__image {
      background-color: $text-color;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(24, 39, 89, 0.6);
        z-index: 1;
      }

      img {
        opacity: .35;
      }
    }
  }

  &--3top-4bottom {
    
    .grid-image__wrap {
      @media(min-width: 1024px) {
        justify-content: center;
      }
    }

    .grid-image__item {
      @media(min-width: 1024px) {
        width: calc((100% - 62px) / 4);
        margin-right: 20px;
        margin-bottom: 20px;
  
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: calc((100% - 42px) / 3);
          margin-right: 20px;
          margin-bottom: 20px;
        }
  
        &:nth-child(4n + 3) {
          margin-right: 0;
        }
      }
    }

    .grid-image__title {
      @media(min-width: 1024px) and (max-width: 1300px) {
        font-size: 26px;
      }

      @media(min-width: 1301px) and (max-width: 1439px) {
        font-size: 30px;
      }
    }

    @media(min-width: 1024px) and (max-width: 1439px) {
      .grid-image__content {
        padding: 15px;
      }
  
      .grid-image__link {
        a {
          right: 10px;
        }
      }
    }

    .grid-image__type {
      @media(min-width: 1024px) and (max-width: 1439px) {
        bottom: 15px;
        left: 10px;
      }

      span {
        @media(min-width: 1024px) and (max-width: 1439px) {
          width: 32px;
          height: 32px;
        }

        @media(min-width: 1301px) and (max-width: 1439px) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.pole-divas-white {
  background: url('../images/pole-divas.png') no-repeat scroll 0 0 transparent;
  font-size: 0;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-size: 100% auto;
  margin-left: 5px;

  @include susy-media(desktop) {
    width: 52px;
    height: 52px;
  }
}

.aerial-divas-white {
  background: url('../images/aerial-divas.png') no-repeat scroll 0 0 transparent;
  font-size: 0;
  display: inline-block;
  width: 35px;
  height: 35px;
  background-size: 100% auto;

  @include susy-media(desktop) {
    width: 52px;
    height: 52px;
  }
}
