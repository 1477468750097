.list-course {
  padding: 32px 0 56px;

  @include susy-media(desktop) {
    padding: 70px 0;
  }

  .container-medium {
    @include susy-media(desktopWide) {
      padding: 0;
    }
  }

  .section-title,
  .section-description {
    text-align: center;
  }

  .section-description {
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 800;
    padding: 10px 0 13px;
    line-height: 1.2;
  }

  &__item {
    background-color: $white;
    padding: 26px 15px 38px;
    margin-top: 26px;
    color: $text-color;

    @include susy-media(tablet) {
      padding: 40px 15px 48px;
      margin-top: 37px;
    }

    &:nth-child(2n) {
      margin-top: 45px;

      @include susy-media(desktopWide) {
        padding-top: 52px;
        padding-bottom: 53px;
      }

      .list-course__title {
        color: $pink;
      }

      .list-course__image {
        padding-top: 13px;
        padding-right: 0;
      }
    }
  }



  &__bottom {
    text-align: center;

    .list-course__title {
      width: 100%;
    }
  }

  &__title,
  &__body {
    @include susy-media(tablet) {
      width: 45%;
    }
  }

  &__title {
    margin-bottom: 9px;
    font-size: 28px;
  }

  &__image {
    width: 50%;
    line-height: 0;
    text-align: center;
    float: right;
    margin-left: 39px;

    @include susy-media(desktop) {
      padding-right: 50px;
    }
  }
  &__top {
    margin-bottom: 32px;
    overflow: hidden;

    @include susy-media(desktopWide) {
      padding-left: 125px;
      min-height: 258px;
    }

    @include susy-media(mobileOnly) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;

      img {
        width: 100%;
        height: auto;
      }

      * {
        width: 100%;
      }

      .list-course__title {
        order: 1;
      }

      .list-course__image {
        order: 2;
        padding: 0;
        margin-bottom: 20px;
      }

      .list-course__body {
        order: 3;
      }
    }
  }

  &__btn {
    a {
      margin: 0 15px 15px;

      @include susy-media(tablet) {
        margin: 0 15px 5px;
      }
    }
  }

  ul {
    padding: 24px 0 33px;

    li {
      list-style: none;
      display: inline-block;
      font-weight: 800;
      text-transform: uppercase;
      padding: 0 15px;
      font-size: 21px;
      position: relative;

      a {
        color: $text-color;

        &:hover {
          color: $link-color;
        }
      }

      &:before {
        content: '>';
        position: absolute;
        right: -8px;
        font-size: 21px;
        display: inline-block;
      }

      &:last-child:before {
        display: none;
      }
    }
  }
}
