.list-filter {
  @include susy-media(mobileTabletOnly) {
    background: $gray-f0;
    padding: 8px 20px;
    border-radius: 15px;
    margin-bottom: 26px;
  }

  &__label {
    display: block;
    text-align: center;
    padding: 0 0 11px;
    line-height: 1.7;

    @include susy-media(mobileTabletOnly) {
      font-size: 16px;
      padding-bottom: 0;

      + div {
        margin-top: 7px;
        display: none;
      }
    }

    @include susy-media(desktop) {
      & + div {
        display: block!important;
      }
    }
  }

  .menu {
    padding: 0;
    margin: 0;

    @include susy-media(tabletOnly) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    li {
      list-style: none;
      padding: 0;
      margin: 0 0 10px 0;

      @include susy-media(tabletOnly) {
        width: calc((100% - 30px) / 2);
      }
    }

    a {
      background: $blue;
      color: $white;
      display: block;
      padding: 26px 31px 23px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 21px;
      transition: .5s;

      @include susy-media(mobileTabletOnly) {
        padding: 20px 31px 17px;
      }

      &:hover,
      &.active {
        background-color: $pink;
        transition: .5s;
      }

      &.icon {
        padding-left: 90px;

        &.strength {
          background: url('../images/strength.png') no-repeat scroll 23px center $blue;
        }

        &.stretch {
          background: url('../images/stretch.png') no-repeat scroll 23px center $blue;
        }

        &.dance {
          background: url('../images/dance.png') no-repeat scroll 23px center $blue;
        }

        &.aerial-divas {
          background: url('../images/aerial-divas-white.png') no-repeat scroll 23px center $blue;
        }

        &.pole-divas {
          background: url('../images/pole-divas-white.png') no-repeat scroll 23px center $blue;
        }

        &.signature {
          background: url('../images/flexibility-white.png') no-repeat scroll 23px center $blue;
        }

        &:hover,
        &.active {
          background-color: $pink;
          transition: .5s;
        }
      }
    }
  }
}

.view-filters {
  a {
    background: #191f51;
    color: $white;
    display: block;
    padding: 26px 24px 23px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 21px;
    transition: .5s;

    &.active,
    &:hover {
      transition: .5s;
      background-color: $pink;
    }
  }

  .form-item {
    margin-bottom: 10px;
  }

  .form-label {
    display: block;
    text-align: center;
    padding: 0px 5px 16px;
    font-size: 16px;
    line-height: 1;
  }
}
