// Style table

table {
  margin-bottom: rem(15px);
  width: 100%;

  @include susy-media(desktop) {
    min-width: 100%;
  }
}

td,
th {
  border-bottom: rem(1px) solid $black;
  padding: 5px 10px;
  vertical-align: top;

  @include susy-media(mobileLandscape) {
    padding: rem(10px 18px);
  }

  @include susy-media(desktop) {
    min-width: rem(120px);
  }

  tbody tr:first-of-type & {
    border-top: rem(1px) solid $black;
  }
}

th {
  font-weight: 700;
}

.table-responsive {
  margin-bottom: rem(15px);
  overflow-x: auto;
  width: 100%;

  table {
    margin-bottom: 0;
  }
}

ul.tabs.primary {
  margin-top: rem(30px);
  border-bottom: rem(1px) solid #ddd;
  padding: rem(0 15px);

  > li {
    display: inline-block;
    margin-right: rem(10px);

    a {
      padding: 5px 20px;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      border-radius: 5px 5px 0 0;
      background: #f9f9f9;
      border: 1px solid #ddd;
      border-bottom: 0;
    }

    &.active a {
      background: #f5f5f5;
      border: 1px solid #ccc;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
    }
  }
}

.action-links {
  margin: 0;
  padding: 0;

  li {
    list-style: none;

    a:before {
      content: "+";
      margin-right: 7px;
    }
  }
}
