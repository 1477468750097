.grid-text {
  @include susy-media(mobileOnly) {
    padding: 26px 0;
  }

  &__wrap {
    @include susy-media(tablet) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    background-color: $gray-f0;
    padding: 30px 24px 30px 15px;
    margin-bottom: 20px;

    @include susy-media(tablet) {
      height: 235px;
    }


    h3 {
      font-size: 28px;
    }

    &:last-child {
       margin-bottom: 0;
    }

    @include susy-media(tablet) {
      width: calc((100% - 30px ) / 2);
      padding: 50px 25px 35px 50px;
      margin-bottom: 0;
    }

    .mCSB_scrollTools .mCSB_draggerRail {
      width: 5px;
    }

    .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: $blue-59;
      width: 5px;
    }
  }
}
