.box-media {
  text-align: right;
  padding-top: 46px;
  position: relative;
  z-index: 2;

  @include susy-media(tablet) {
    z-index: 4;
  }

  &__content {
    padding-bottom: 30px;
    text-align: left;

    @include susy-media(desktop) {
      width: 28%;
      float: left;
      padding-top: 17px;
      padding-right: 20px;
      position: relative;
      left: -23px;
    }
  }

  &__wrap {
    @extend .padding-space;
    position: relative;
    bottom: -49px;
    width: 100%;
    overflow: hidden;
  }

  &__media {
    @include susy-media(desktop) {
      width: 72%;
      float: left;
    }
  }

  &__subtitle {
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1.2;
    letter-spacing: 3.3px;

    @include susy-media(mobileOnly) {
      font-size: 14px;
      letter-spacing: 2px;
    }
  }

  .video-wrap {
    margin-bottom: 0;
  }

  //
  &--type1 {
    margin-bottom: 100px;
    padding-bottom: 0;

    @include susy-media(mobileOnly) {
      padding-top: 0;
    }

    @include susy-media(desktopWide) {
      margin-bottom: 134px;
    }

    @include susy-media(tablet) {
      padding-top: 7px;
      padding-bottom: 3px;
    }

    &:before {
      background-position: 40px 0;
    }

    .box-media {
      &__title {
        @extend .title--largest;
        margin-bottom: 17px;

        @include susy-media(tablet) {
          margin-bottom: 32px;
        }
      }

      &__content {
        text-align: left;
        padding: 0;
        left: 0;

        @include susy-media(desktop) {
          width: 100%;
          float: none;
        }
      }

      &__subtitle {
        @extend .title--small-large;
      }

      &__title,
      &__subtitle {
        color: $pink;
      }

      &__wrap {
        position: relative;
        bottom: -56px;
        width: 100%;
        display: inline-block;

        @include susy-media(tablet) {
          bottom: -92px;
        }

        @include susy-media(desktop) {
          width: 76.8%;
          padding-right: 10px;
          padding-left: 0;
        }
      }

      &__media {
        @include susy-media(tablet) {
          padding-left: 140px;
        }

        @include susy-media(desktop) {
          width: 100%;
        }
      }
    }
  }

  &--type2 {
    text-align: center;
    padding: 71px 0 24px;

    @include susy-media(tablet) {
      padding: 76px 0;
    }

    .box-media {
      &__title {
        margin-bottom: 27px;
        font-size: 37px;
        line-height: 1.2;

        @include susy-media(mobilePortraitOnly) {
          font-size: 28px;
        }

        span {
          color: $pink;
        }
      }

      &__content {
        text-align: center;
        width: 100%;
        float: none;
        padding: 0;
      }

      &__description {
        margin-bottom: 36px;

        @include susy-media(tablet) {
          margin-bottom: 46px;
        }
      }

      &__media {
        width: 100%;
        margin: 0 auto;

        @include susy-media(desktop) {
          max-width: 75%;
          text-align: center;
          float: none;
        }
      }

      &__wrap {
        bottom: 0;
      }
    }
  }

  &--type3 {
    @include susy-media(tablet) {
      margin-bottom: 80px;
      padding-top: 15px;
    }

    @include susy-media(desktop) {
      margin-bottom: 138px;
      padding-top: 25px;
    }

    @include susy-media(mobileOnly) {
      padding-top: 38px;
      padding-bottom: 40px;
      margin: 0;
    }

    .box-media__title {
      margin-bottom: 15px;

      @include susy-media(tablet) {
        margin-bottom: 30px
      }
    }

    .box-media__wrap {
      bottom: 0;

      @include susy-media(tablet) {
        bottom: -30px;
      }

      @include susy-media(desktop) {
        bottom: -75px;
      }
    }

    .box-media__content {
      left: 0;
      padding-bottom: 11px;

      @include susy-media(mobileOnly) {
        text-align: center;
        padding-bottom: 22px;
      }

      @include susy-media(desktop) {
        padding: 47px 10px 0 0;
        width: 31%;
      }

      @include susy-media(desktopWide) {
        padding: 47px 10px 0 55px;
      }
    }

    .box-media__media {
      @include susy-media(desktop) {
        width: 69%;
        float: left;
        padding-left: 8px;

        > img {
          max-width: 712px;
        }
      }
    }
  }

  &--type4 {
    padding: 30px 0;

    @include susy-media(tablet) {
      padding: 53px 0 65px;
    }

    .box-media__wrap {
      bottom: 0;
    }

    .box-media__content {
      left: 0;
      padding-bottom: 11px;

      @include susy-media(desktop) {
        padding: 56px 10px 0 0;
        width: 31%;
      }

      @include susy-media(desktopWide) {
        padding: 56px 10px 0 55px;
      }
    }

    .box-media__media {
      @include susy-media(desktop) {
        width: 69%;
        float: left;
        padding-left: 8px;

        > img {
          max-width: 712px;
        }
      }
    }
  }

  &--image {
    padding: 30px 0;

    &:before {
      background-image: url('../images/bg-gray-image.png');
      width: 50%;

      @include susy-media(mobileOnly) {
        width: 100%;
      }
    }

    @include susy-media(tablet) {
      padding: 50px 0;
    }

    .box-media__content {
      @include susy-media(desktopWide) {
        width: 32%;
      }
    }

    .box-media__media {
      @include susy-media(desktopWide) {
        width: 68%;
      }
    }

    .box-media__title {
      line-height: 1.2;
    }

    .box-media__link {
      margin-top: 42px;
    }

    .box-media__wrap {
      bottom: 0;
    }
  }
}

.js-text-slider {
  display: inline-block !important;
  min-width: 164px;
  line-height: 1;
  text-align: left;

  @include susy-media(mobileOnly) {
    width: 100%;
    text-align: center;
  }
}
