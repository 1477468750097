%btn,
.btn {
  @include opensans;
  appearance: none;
  background-image: none;
  border-radius: 20px;
  border: 0;
  box-shadow: none;
  display: inline-block;
  font-size: 14px;
  line-height: 1.2;
  outline: none;
  // padding: 7px 25px;
  padding: 7px 38px;
  text-align: center;
  text-shadow: none;
  transition: all .3s ease;
  background-color: $btn-color;
  color: $white;
  cursor: pointer;
  outline: 0;
  font-weight: 600;
  min-width: 190px;

  &:active,
  &:hover {
    background-color: $btn-color-hover;
    border-color: $btn-color-hover;
    color: $white;
  }

  &--white {
    background-color: $white;
    color: $blue-59;

    &:hover {
      background-color: $blue-59;
      color: $white;
    }
  }

  &--pink {
    background-color: $pink;
    color: $white;

    &:hover {
      background-color: $blue-59;
      color: $white;
    }
  }

  &--large {
    min-width: 200px;
    padding: 10px;

    @include susy-media(desktop) {
      min-width: 250px;
    }
  }

  &--play {
    background: url('../images/btn-play.png') no-repeat scroll 0 0 transparent;
    width: 134px;
    height: 134px;
    background-size: 70px;
    background-position: center;

    @include susy-media(tablet) {
      background-size: 90px;
    }

    @include susy-media(desktop) {
      background-size: 134px;
    }

    &:active,
    &:hover {
      background-color: transparent;
    }
  }
}
