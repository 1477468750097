.promotion-banner {
  overflow: hidden;

  @include susy-media(mobileOnly) {
    margin-bottom: -54px;
  }

  &__wrap {
    text-align: center;

    @include susy-media(desktop) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__left {
    width: 100%;
    padding: 62px 0 50px 0;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 35px 20px $white;
    background-color: $white;
    text-align: left;

    @include susy-media(desktop) {
      box-shadow: none;
      background-color: transparent;
    }

    @include susy-media(tablet) {
      padding: 80px 0 40px 0;
    }

    @include susy-media(desktop) {
      width: 55%;
      padding: 60px 30px 80px 0;

      &:before {
        background-color: $gray-f0;
        content: '';
        position: absolute;
        top: -170%;
        left: -300%;
        right: 0;
        bottom: -50%;
        transform: rotate(14deg);
      }

      &:after {
        content: '';
        background-color:  $gray-f0;
        width: 800px;
        height: 200%;
        top: -200px;
        right: 42px;
        bottom: 1px;
        position: absolute;
        transform: rotate(14deg);

        @include susy-media(desktopWide) {
          height: 260%;
        }
      }
    }

    @include susy-media(desktopWide) {
      padding: 80px 30px 104px 0;
      width: 69%;
    }

    * {
      position: relative;
      z-index: 9;
    }
  }

  &__right {
    display: inline-block;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-top: -100px;

    @include susy-media(desktop) {
      width: 100%;
      text-align: left;
    }

    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 25px;
      height: 70px;
      box-shadow: 0 0 35px 20px $white;
      content: "";
      background-color: $white;

      @include susy-media(tablet) {
        height: 50px;
        bottom: 0;
      }

      @include susy-media(desktop) {
        display: none;
      }
    }

    @include susy-media(mobilePortraitOnly) {
      margin-top: -50px;
    }

    @include susy-media(tablet) {
      margin-top: -20px;
    }

    @include susy-media(desktop) {
      margin-top: 0;
      text-align: right;
      width: 47%;
      right: -30px;
    }

    @include susy-media(desktopWide) {
      width: 42%;
      right: -87px;
    }

    img {
      height: 100%;
      width: auto;

      @include susy-media(mobileOnly) {
        height: auto;
      }

      @include susy-media(tabletOnly) {
        max-height: 500px;
      }
    }
  }

  &__subtitle {
    @extend .title--small;
  }

  &__title {
    margin-bottom: 24px;

    @include susy-media(tablet) {
      margin-bottom: 32px;
    }

    sup {
      font-size: 20px;
    }

    sub {
      vertical-align: baseline;
      font-size: 25px;
      text-transform: uppercase;
      position: relative;
      top: -2px;
    }
  }

  &__body {
    @include susy-media(mobileOnly) {
      line-height: 1.75;
    }
  }

  &__link-first {
    margin-top: 24px;

    @include susy-media(desktop) {
      margin-top: 42px;
    }
  }

  // with image
  &--with-image {
    @include susy-media(desktop) {
      // height: 652px;
      height: auto;
    }

    .promotion-banner {
      &__subtitle {
        margin-bottom: 1px;

        @include susy-media(tablet) {
          margin-bottom: -3px;
        }
      }

      &__title {
        @extend .title--large;
      }

      &__link-second {
        position: absolute;


        @include susy-media(mobileTabletOnly) {
          bottom: 85px;
          left: 50%;
          transform: translate(-50%, 0);
        }

        @include susy-media(mobileOnly) {
          bottom: 100px;
        }

        @include susy-media(desktop) {
          right: 135px;
          bottom: 110px;
        }
        // left: 0;
        // top: 50%;
        // left: 5px;
        // top: 260px;

        // @include susy-media(tablet) {
        //   left: -5px;
        //   top: 225px;
        // }
        //
        // @include susy-media(desktop) {
        //   left: 30px;
        //   top: 260px;
        // }
        //
        // @include susy-media(1050px) {
        //   left: 10%;
        // }
        //
        // @include susy-media(1090px) {
        //   left: 14%;
        // }
        //
        // @include susy-media(1130px) {
        //   left: 17%;
        // }
        //
        // @include susy-media(1160px) {
        //   left: 18%;
        // }
        //
        // @include susy-media(desktopWide) {
        //   left: 25px;
        // }

        a {
          min-width: inherit;
        }
      }
    }
  }

  // With text
  &--with-text {
    @include susy-media(mobileOnly) {
      margin-bottom: 7px;
    }

    .promotion-banner {
      &__left {
        padding: 45px 20px 23px;
        box-shadow: none;
        text-align: center;

        @include susy-media(mobileTabletOnly) {
          padding-top: 38px;
          width: auto;
          background-color: #f0f2f5;
          margin-left: -30px;
          margin-right: -30px;
        }

        @include susy-media(mobileOnly) {
          margin-left: -20px;
          margin-right: -20px;
        }

        @include susy-media(desktop) {
          text-align: left;
          width: 60%;
          padding: 45px 70px 50px 0;
        }
      }

      &__right {
        text-align: center;
        margin-top: 0;

        &:before {
          display: none;
        }

        @include susy-media(mobileTabletOnly) {
          padding: 24px 5px;
        }

        @include susy-media(desktop) {
          width: 40%;
          padding: 0px 55px 8px 0px;
        }
      }

      &__subtitle {
        @include susy-media(mobileTabletOnly) {
          font-size: 19px;
        }
      }

      &__title {
        font-size: 37px;
        margin-bottom: 27px;

        @include susy-media(desktop) {
          margin-bottom: 32px;
        }
      }

      &__description {
        font-weight: 800;
        font-size: 19px;
        text-transform: uppercase;
        line-height: 1.3;
        margin: 0 auto;

        @include susy-media(tablet) {
          font-size: 21px;
          line-height: 1.1;
        }

        @include susy-media(desktop) {
          max-width: 280px;
        }
      }

      &__link-second {
        margin-top: 22px;
      }

      &__link-first {
        margin-top: 30px;

        @include susy-media(desktop) {
          margin-top: 35px;
        }
      }
    }
  }
}
