.box-news {
  clear: both;

  .title--small {
    color: $blue-59;
  }

  .view-header {
    h2 {
      font-size: 37px;

      @include susy-media(tabletOnly) {
        margin-bottom: 21px;
      }

      @include susy-media(mobilePortraitOnly) {
        font-size: 28px;
      }
    }
  }

  &__item {
    background-color: $gray-f3;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;

    @include susy-media(tabletOnly) {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.news,
    &.events,
    .news,
    .event {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 0 solid transparent;
        border-bottom: 56px solid transparent;
        border-left: 56px solid $text-color;
      }

      &:after {
        background: url('../images/news.png') no-repeat scroll 0 0 transparent;
        content: '';
        position: absolute;
        left: 7px;
        top: 6px;
        width: 18px;
        height: 18px;
        background-size: 100% auto;
        visibility: visible;
      }
    }

    &.events,
    .event {
      &:before {
        border-left: 56px solid $pink;
      }

      &:after {
        background: url('../images/evens.png') no-repeat scroll 0 0 transparent;
        background-size: 100% auto;
      }

      .box-news__title a {
        color: $pink;

        &:hover {
          color: $text-color;
        }
      }
    }
  }

  &__image {
    float: left;
    line-height: 0;

    @include susy-media(mobilePortraitOnly) {
      margin-right: 10px;
      max-width: 75px;
      margin-bottom: 5px;
    }
  }

  &__content {
    padding: 10px;
    overflow: hidden;

    @include susy-media(mobileLandscape) {
      padding: 11px 12px 40px 20px;
    }

    @include susy-media(tablet) {
      padding: 14px;
    }

    @include susy-media(desktopWide) {
      padding: 14px 20px 0;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 5px;

    @include susy-media(mobileLandscape) {
      font-size: 19px;
    }

    @include susy-media(tablet) {
      font-size: 16px;
    }

    @include susy-media(desktopWide) {
      font-size: 21px;
    }

    a {
      color: $text-color;

      &:hover {
        color: $link-color;
      }
    }
  }

  &__body {
    font-size: 12px;
    line-height: 1.2;

    .two-col .item {
      width: 100%;
      margin: 0;
    }
  }

  &__link {
    position: absolute;
    bottom: 13px;

    a {
      min-width: 140px;

      @include susy-media(mobilePortraitOnly) {
        font-size: 12px;
      }
    }
  }
}

.pane-views-articles-block {
  .box-news__item {
    margin-bottom: 10px;
  }

  .box-news__content {
    @include susy-media(mobileLandscape) {
      padding: 11px 12px 11px 20px;
    }
  }
}

.node-article.news {
  .field-name-field-image {
    display: none;
  }
}

.view-display-id-block_1  {
  .box-news__content {
    padding-bottom: 40px;
  }
}

.views_infinite_scroll-ajax-loader {
  display: none;
}
