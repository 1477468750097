.text-small {
  font-size: 18px;

  @include susy-media(desktop) {
    font-size: 21px;
  }
}

.text-medium {
  font-size: 18px;

  @include susy-media(desktop) {
    font-size: 25px;
  }
}

.text-large {
  font-size: 21px;

  @include susy-media(desktop) {
    font-size: 28px;
  }
}

.padding-box {
  padding-bottom: 28px;

  @include susy-media(tablet) {
    padding-bottom: 65px;
  }

  @include susy-media(900px) {
    padding-bottom: 45px;
  }
}

.box-quote {
  padding: 22px 0 117px;
  text-align: center;
  overflow: hidden;

  @include susy-media(tablet) {
    padding: 90px 0 260px;
  }

  h2 {
    @include susy-media(mobileOnly) {
      font-size: 37px;
    }

    @include susy-media(mobilePortraitOnly) {
      font-size: 28px;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;

    @include susy-media(mobileOnly) {
      position: relative;
      height: 355px;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      max-width: 440px;
      margin-left: auto;
      margin-right: auto;
    }

    @include susy-media(mobilePortraitOnly) {
      height: 550px;
    }
  }

  &__item {
    width: 25%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 15px;

    @include susy-media(mobileOnly) {
      width: 100%;
    }

    span {
      position: relative;
      z-index: 2;
      padding: 10px 20px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          content: '';
          background: url('../images/quote-blue.png') no-repeat scroll 0 0 transparent;
          background-size: 100% auto;
        }
      }
    }

    &:nth-child(2),
    &:nth-child(7) {
      > div {
        &:before {
          background: url('../images/quote-pink.png') no-repeat scroll 0 0 transparent;
          background-size: 100% auto;
        }
      }
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(8) {
      > div {
        width: 245px;
        height: 170px;

        &:before {
          background: url('../images/quote-blue-light.png') no-repeat scroll 0 0 transparent;
          background-size: 100% auto;
        }
      }
    }

    &:nth-child(1),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      > div {
        @extend .text-large;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      > div {
        @extend .text-medium;
      }
    }

    &:nth-child(2),
    &:nth-child(5) {
      > div {
        @extend .text-small;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(6) {
      @include susy-media(mobileOnly) {
        display: none;
      }
    }

    &:nth-child(1) {
      > div {
        width: 190px;
        height: 135px;
        transform: rotate(10deg);
        padding-bottom: 25px;

        @include susy-media(tablet) {
          position: relative;
          top: -44px;
          right: 0;
        }

        @include susy-media(desktop) {
          right: -30px;
        }

        @include susy-media(desktopWide) {
          right: -85px;
        }

        &:before {
          transform: scaleX(-1);
        }
      }
    }

    &:nth-child(2) {
      @include susy-media(mobileOnly) {
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }

      > div {
        width: 200px;
        height: 128px;
        padding-bottom: 20px;
        transform: rotate(0deg);
        line-height: 1.2;
        padding: 10px 15px 29px;

        @include susy-media(mobileOnly) {
          width: 155px;
          height: 95px;
        }

        @include susy-media(tablet) {
          position: relative;
          top: 37px;
          left: -30px;
        }

        &:before {
          transform: scaleX(-1);
        }
      }
    }

    &:nth-child(3) {
      @include susy-media(mobileOnly) {
        position: absolute;
        left: 10px;
        top: 10px;
        width: auto;
      }

      @include susy-media(mobilePortraitOnly) {
        top: 68px;
        left: 0;
      }

      > div {
        @extend .padding-box;
        transform: rotate(4deg);

        @include susy-media(mobileOnly) {
          width: 192px;
          font-size: 20px;
          height: 136px;
        }

        @include susy-media(tablet) {
          position: relative;
          bottom: -34px;
          left: 0;
        }

        @include susy-media(desktop) {
          left: -55px;
          padding-bottom: 38px;
        }

        &:before {
          transform: rotate(-4deg);
        }
      }
    }

    &:nth-child(4) {
      @include susy-media(mobileOnly) {
        position: relative;
        left: 32px;
        top: 0;
        width: auto;
      }

      > div {
        @extend .padding-box;
        width: 250px;
        height: 170px;
        transform: rotate(-5deg);

        @include susy-media(mobileOnly) {
          width: 195px;
          height: 138px;
          font-size: 20px;
        }

        @include susy-media(tablet) {
          position: relative;
          top: -69px;
          left: 0;
        }

        @include susy-media(desktop) {
          left: -50px;
          top: -40px;
          padding-bottom: 38px;
        }

        @include susy-media(desktopWide) {
          left: -139px;
          top: -69px;
        }

        &:before {
          transform: rotate(3deg);
        }

        span {
          padding: 20px;
        }
      }
    }

    &:nth-child(5) {
      > div {
        @extend .padding-box;
        transform: rotate(-8deg);

        @include susy-media(tablet) {
          position: relative;
          top: -13px;
          left: 0;
        }

        @include susy-media(desktop) {
          left: 30px;
          padding-bottom: 36px;
        }

        @include susy-media(desktopWide) {
          left: 100px;
        }

        &:before {
          transform: rotate(-4deg);
        }

        span {
          padding: 25px;
        }
      }
    }

    &:nth-child(6) {
      > div {
        @extend .padding-box;
        width: 246px;
        height: 170px;
        transform: rotate(-2deg);

        @include susy-media(tablet) {
          position: relative;
          left: 18px;
        }

        @include susy-media(desktop) {
          left: 30px;
          padding-bottom: 35px;
        }

        @include susy-media(desktopWide) {
          left: 68px;
        }

        &:before {
          transform: rotate(3deg);
        }

        span {
          @include susy-media(tablet) {
            padding: 0 20px;
          }

          @include susy-media(desktop) {
            padding: 0 46px;
          }
        }
      }
    }

    &:nth-child(7) {
      @include susy-media(mobileOnly) {
        position: absolute;
        left: 0;
        bottom: 29px;
        width: auto;
      }

      @include susy-media(mobilePortraitOnly) {
        bottom: 72px;
      }

      > div {
        width: 222px;
        height: 135px;
        transform: rotate(-2deg);
        padding-bottom: 40px;

        @include susy-media(mobileOnly) {
          width: 173px;
          font-size: 22px;
          height: 125px;
        }

        @include susy-media(tablet) {
          position: relative;
          bottom: -68px;
          left: 17px;
        }

        @include susy-media(900px) {
          padding-bottom: 28px;
        }

        @include susy-media(desktop) {
          padding-bottom: 20px;
        }

        &:before {
          transform: rotate(2deg);
        }
      }
    }

    &:nth-child(8) {
      @include susy-media(mobileOnly) {
        position: absolute;
        right: -12px;
        bottom: -29px;
        width: auto;
      }

      > div {
        @extend .padding-box;
        width: 248px;
        height: 172px;
        transform: rotate(-5deg);

        @include susy-media(mobileOnly) {
          width: 193px;
          height: 135px;
        }

        @include susy-media(tablet) {
          position: relative;
          top: -34px;
          left: 0;
        }

        @include susy-media(desktop) {
          left: 0;
          padding-bottom: 30px;
        }

        @include susy-media(desktopWide) {
          left: -104px;
        }

        &:before {
          transform: scaleX(-1) rotate(-7deg);
        }
      }
    }
  }
}
