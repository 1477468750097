.list-image-circle {
  margin-top: -162px;

  @include susy-media(mobileTabletOnly) {
    margin-top: -94px;
  }

  &__wrap  {
    display: flex;
    align-items: center;
    justify-content: center;

    @include susy-media(mobileTabletOnly) {
      margin: 0 -7px;
    }
  }

  &__item {
    position: relative;
    margin: 0 10px;

    @include susy-media(tablet) {
      margin: 0 16px;
    }


    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-child(3) {
      @include susy-media(mobileTabletOnly) {
        min-width: 190px;
      }
    }

    &:nth-child(5) {
      margin-right: 0;
    }

    &:nth-child(2) {
      @include susy-media(mobileTabletOnly) {
        margin-left: 0;
      }
    }

    &:nth-child(4) {
      @include susy-media(mobileTabletOnly) {
        margin-right: 0;
      }
    }

    &:nth-child(1),
    &:nth-child(5) {
      @include susy-media(desktop) {
        width: 130px;
        height: 130px;
      }

      @include susy-media(mobileTabletOnly) {
        display: none;
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      @include susy-media(mobilePortraitOnly) {
        display: none;
      }

      @include susy-media(desktop) {
        width: 188px;
        height: 188px;
      }
    }

    &:nth-child(3) {
      @include susy-media(desktop) {
        width: 322px;
        height: 322px;
      }

      img {
        opacity: .1;
      }

      .list-image-circle__image {
        background-color: $text-color;
        border-radius: 100%;
        overflow: hidden;
      }
    }
  }

  &__image {
    line-height: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 10px;
    transform: translate(-50%, -50%);

    @include susy-media(mobileLandscapeOnly) {
      padding: 5px;
    }

    * {
      color: $white;
    }
  }

  &__subtitle {
    margin-bottom: 10px;

    @include susy-media(mobileOnly) {
      font-size: 8.32px;
    }
  }

  &__title {
    margin-bottom: 0;

    @include susy-media(mobileOnly) {
      font-size: 22px;
    }

    @include susy-media(tabletOnly) {
      font-size: 25px;
    }
  }
}
