// FONTS
// Import all webfonts here
//https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6

// For example:
@include font-face(poledivas, fonts/poledivas, 400, normal);
@include font-face(OpenSans, fonts/OpenSans-Bold, 700, normal);
@include font-face(OpenSans, fonts/OpenSans-SemiBold, 600, normal);
@include font-face(OpenSans, fonts/OpenSans-Regular, 400, normal);
@include font-face(OpenSans, fonts/OpenSansCondensed-Bold, 800, normal);
// @include font-face(SourceSansPro, fonts/SourceSansPro-BoldItalic, 700, italic);
// @include font-face(SourceSansPro, fonts/SourceSansPro-Regular, 400, normal);
// @include font-face(SourceSansPro, fonts/SourceSansPro-Italic, 400, italic);
