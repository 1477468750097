.grid-class {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    margin-bottom: 30px;
    background-color: $gray-f0;
    position: relative;

    &:last-child {
      @include susy-media(mobileOnly) {
        margin-bottom: 0
      }
    }

    @include susy-media(tablet) {
      width: calc((100% - 30px) / 2);
      margin-right: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include susy-media(desktopWide) {
      margin-right: 30px;
      width: calc((100% - 62px) / 3);

      &:nth-child(2n) {
        margin-right: 30px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .node {
      height: 100%;
    }

  }

  &__content {
    padding: 16px 20px 50px;

    @include susy-media(desktop) {
      padding: 16px 20px 50px;
    }

    &.with-icon {
      padding: 16px 20px 140px;

      @include susy-media(desktop) {
        padding: 16px 20px 159px;
      }
    }

    &.small {
      padding-bottom: 82px;
    }
  }

  &__image {
    line-height: 0;
    position: relative;

    .btn--play {
      width: 50px;
      height: 50px;
      background-size: 50px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    margin-bottom: 14px;

    @include susy-media(mobileTabletOnly) {
      font-size: 28px;
      margin-bottom: 9px;
    }

    a {
      color: $text-color;

      &:hover {
        color: $pink;
      }
    }
  }

  &__position {
    margin-top: -8px;
    margin-bottom: 16px;
    font-size: 28px;
  }

  &__body {
    @extend .list-style;
    font-size: 12px;

    p,
    div {
      margin-bottom: 6px;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__link {
    a {
      border-radius: 0;
      width: 100%;
      padding: 10px;
    }
  }

  &__type {
    display: flex;
    padding: 0 15px 18px;

    span {
      min-width: 42px;
      padding-top: 49px;
      font-size: 12px;
      display: inline-block;
      max-width: 60px;
      font-weight: 600;
      text-align: center;
      background-position: top center;
      margin-right: 21px;
      line-height: 1.2;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__class {
    display: flex;
    align-items: center;
    padding: 19px 16px;

    .pole-divas-logo,
    .aerial-divas-logo {
      width: 45px;
      height: 45px;
      margin-right: 13px;
      min-width: 45px;
    }

    .pole-divas-title {
      font-weight: 800;
      color: $text-color;
      font-size: 16px;
      letter-spacing: 0;
      margin-bottom: 4px;
    }

    .pole-divas-class {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.strength {
  background: url('../images/strength-blue.png') no-repeat scroll 0 0 transparent;
}

.beginners-friendly {
  background: url('../images/beginner.png') no-repeat scroll 0 0 transparent;
}

.spin-pole-options {
  background: url('../images/spin.png') no-repeat scroll 0 0 transparent;
}

.aerial-divas,
.aerial-divas-logo {
  background: url('../images/aerial-divas-blue.png') no-repeat scroll 0 0 transparent;
}

.dance {
  background: url('../images/dance-blue.png') no-repeat scroll 0 0 transparent;
}

.stretch {
  background: url('../images/stretch-blue.png') no-repeat scroll 0 0 transparent;
}

.flexibility {
  background: url('../images/flexibility.png') no-repeat scroll 0 0 transparent;
}

.pole-divas-logo {
  background: url('../images/pole-divas-blue.png') no-repeat scroll 0 0 transparent;
}

.view-instructor {
  .grid-class__image {
    background-color: #ebeef2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .grid-class__image-circle {
    border: 10px solid #e1e2e6;
    width: 187px;
    height: 187px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      max-width: inherit;
      height: 100%;
    }
  }
}
