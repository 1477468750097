.introductory-list {
  text-align: center;
  padding: 68px 0 18px;

  &__title {
    margin-bottom: 53px;
  }

  ul {
    @extend %clearfix;

    @include susy-media(desktopWide) {
      padding: 0 82px;
    }

    li {
      list-style: none;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
      padding: 0 15px;

      @include susy-media(tablet) {
        width: 50%;
        float: left;
      }

      @include susy-media(desktop) {
        width: calc((100% - 2px) / 3);
      }

      a {
        width: 100%;
        @extend .btn;
        @extend .btn--pink;
      }
    }
  }
}
