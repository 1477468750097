// ICONS.

%icons {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'poledivas';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

[class^='icon-'],
[class*=' icon-'] {
  &:before {
    @extend %icons;
  }
}

.icon-arrow-thin-up:before {
  content: "\e90f";
}

.icon-angle-right:before {
  content: "\e907";
}
.icon-angle-left:before {
  content: "\e90e";
}

.icon-minus:before {
  content: "\e90d";
}

.icon-plus:before {
  content: "\e90b";
}

.icon-close:before {
  content: "\e906";
}

.icon-phone2:before {
  content: "\e90c";
}
.icon-download:before {
  content: "\e908";
}
.icon-user:before {
  content: "\e909";
}
.icon-home:before {
  content: "\e90a";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-youtube-play:before {
  content: "\e900";
}
.icon-spotify:before {
  content: "\e901";
}
.icon-snapchat-ghost:before {
  content: "\e902";
}
.icon-facebook-official:before {
  content: "\e903";
}
