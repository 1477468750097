// LAYOUT
// This is the main layout file.
// This file should be used to define the primary page layout that each of the page types will be extending.
// This includes global styles for html, body, etc.

@viewport{
  zoom: 1;
  width: extend-to-zoom;
}

@-ms-viewport{
  width: extend-to-zoom;
  zoom: 1;
}

//Enable font-smoothing
html {
  @include opensans;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $text-color;
  font-size: $base-font-size; // Don't modify static unit.
  font-smoothing: antialiased;
  font-weight: 400;
  line-height: $base-line-height;
}

body {
  // Disable in production mode;
  // @include show-breakpoints;
  text-size-adjust: none;

  @include susy-media(mobileOnly) {
    font-size: 14px;
    line-height: 1.6;
  }

  &.no-scroll {
    overflow: hidden;
    position: fixed;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
}

.noscroll {
  overflow: hidden;
  height: 100%;
  position: relative;
}


.box-threecols,
.content-bottom {
  padding: 45px 0 25px;

  @include susy-media(tablet) {
    padding: 40px 0 50px;
  }

  @include susy-media(desktop) {
    padding: 70px 0 91px;
  }

  .container {
    display: flex;

    @include susy-media(mobileTabletOnly) {
      flex-wrap: wrap;
    }
  }

  &__item {
    &.first {
      width: 100%;

      @include susy-media(mobileLandscape) {
        width: 50%;
        padding-right: 20px;
      }

      @include susy-media(desktop) {
        min-width: 275px;
        width: 20%;
        display: block;
        margin-right: 20px;
        padding: 0;
      }

      @include susy-media(desktopWide) {
        width: 275px;
        margin-right: 29px;
      }
    }

    &.second {
      width: 100%;

      @include susy-media(mobileLandscape) {
        width: 50%;
      }

      @include susy-media(desktop) {
        min-width: 275px;
        width: 20%;
        display: block;
      }

      @include susy-media(desktopSuperwide) {
        width: 275px;
      }
    }

    &.third {
      width: 100%;
      margin-bottom: 15px;

      @include susy-media(desktop) {
        width: calc(60% - 22px);
        padding-left: 20px;
        margin-bottom: 0;
      }

      @include susy-media(desktopWide) {
        padding-left: 87px;
      }
    }
  }
}

.box-bg-image {
  background-color: $blue-1a;
  position: relative;
  margin-bottom: 100px;

  @include susy-media(desktopWide) {
    margin-bottom: 140px;
  }

  &:before {
    @include susy-media(tablet) {
      content: '';
      bottom: 0;
      left: 0;
      position: absolute;
      width: 500px;
      height: 100%;
      background: url('../images/bg-video.png') no-repeat scroll left bottom transparent;
      opacity: .22;
      background-size: auto 100%;
      background-position: 29px 0;
    }
  }
}

.two-cols {
  padding: 30px 0 15px;

  @include susy-media(desktop) {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    @include susy-media(desktop) {
      width: 75%;
    }
  }

  &__sidebar {
    min-width: 250px;

    @include susy-media(desktop) {
      width: 25%;
      min-width: 279px;
    }
  }

  &.right-sidebar {
    .two-cols__content {
      @include susy-media(desktop) {
        padding-right: 30px;
      }

      @include susy-media(desktopWide) {
        padding-right: 47px;
      }
    }

    .two-cols__sidebar {
      @include susy-media(desktopWide) {
        padding-left: 7px;
      }
    }
  }

  &.left-sidebar {
    .two-cols__sidebar {
      @include susy-media(desktop) {
        padding-right: 30px;
      }

      @include susy-media(desktopWide) {
        padding-right: 47px;
      }
    }

    .two-cols__content {
      @include susy-media(desktopWide) {
        padding-left: 7px;
      }
    }
  }

  &.with-form {
    padding: 30px 0 21px;

    .two-cols__content {
      margin-bottom: 24px;

      @include susy-media(desktop) {
        margin-bottom: 0;
        padding-right: 30px;
        width: 71%;
      }

      h2 {
        @extend .title--large;
        margin-bottom: 18px;

        @include susy-media(mobileOnly) {
          display: none;
        }
      }

      p {
        margin-bottom: 11px;

        @include susy-media(desktop) {
          margin-bottom: 25px;
        }
      }

      h3 {
        font-size: 21px;
        color: $pink;
        margin-bottom: 28px;

        @include susy-media(desktop) {
          margin-bottom: 34px;
        }
      }

      iframe {
        height: 264px;

        @include susy-media(desktop) {
          height: 338px;
        }

        @include susy-media(desktopWide) {
          height: 327px;
        }
      }
    }

    .two-cols__sidebar {
      @include susy-media(desktop) {
        width: 29%;
        padding: 0;
      }

      .form-contact {
        width: 100%;
      }
    }
  }
}

.node-type-studio {
  .content-bottom {
    padding: 30px 0;

    @include susy-media(desktop) {
      padding: 65px 0 62px;
    }

    @include susy-media(desktopWide) {
      padding: 65px 25px 62px;
    }
  }

  .content-bottom__item {
    &.third {
      order: 1;

      @include susy-media(desktop) {
        padding-left: 0;
        padding-right: 20px;
        width: 54%;
      }

      @include susy-media(desktopWide) {
        padding-right: 42px;
      }
    }

    &.first {
      order: 2;
    }

    &.second {
      order: 3;
    }
  }
}

.pane-poledivas-studio-google-map {
  iframe {
    height: 305px;
    width: 100%;

    @include susy-media(tablet) {
      height: 420px;
    }
  }
}

main {
  min-height: 615px;
}

.region-content {
  padding: 30px 0;
}

.page-full {
  .region-content {
    padding: 0;
  }
}

.front {
  .group-tabs {
    display: none;
  }

  .content-bottom__item {
    @include susy-media(mobileTabletOnly) {
      display: none;
    }

    &.first {
      order: 2;
    }

    &.second {
      order: 3;
    }

    &.third {
      order: 1;
      @include susy-media(desktop) {
        width: calc(60% - 22px);
        padding-left: 0;
        padding-right: 20px;
        margin-bottom: 0;
      }

      @include susy-media(desktopWide) {
        padding-left: 0;
        padding-right: 87px;
      }
    }
  }

  .box-threecols, .content-bottom {
    @include susy-media(mobileTabletOnly) {
      padding: 0;
    }
  }

  .content-bottom {

  }
}
