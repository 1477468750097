.front {
  .box-form {
    @include susy-media(tablet) {
      padding-top: 202px;
    }
  }
}

.box-form {
  padding: 47px 0 50px;
  position: relative;
  color: $white;
  border-bottom: 5px solid $white;

  @include susy-media(tablet) {
    padding: 147px 0 57px;
  }

  &__wrap {
    position: relative;
    z-index: 1;
  }

  .bg-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-size: cover;
    background-position: center;
  }

  .form-group,
  .fieldset-wrapper {
    padding: 15px 0 6px;

    @include susy-media(tablet) {
      display: flex;
      // margin-right: 5px;
      padding: 0;
    }

    .form-item {
      margin: 0 0 43px;

      @include susy-media(tablet) {
        margin: 0 13px;
      }
    }

    input {
      background-color: transparent;
      height: auto;
      color: $white;
      border: 0;
      border-bottom: 1px solid $white;
      padding: 10px 18px;

      @include susy-media(tablet) {
        min-width: 200px;
      }

      @include susy-media(desktop) {
        min-width: 250px;
      }

      &::-ms-clear {
        display: none;
      }

      &::-webkit-input-placeholder {
        color: lighten($white, 10%);
        opacity: 1;
      }

      &::-moz-placeholder {
        color: lighten($white, 10%);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: lighten($white, 10%);
        opacity: 1;
      }
    }
  }

  .form-subscribe,
  .form-subscribe > div {
    position: relative;

    @include susy-media(tablet) {
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  fieldset.webform-component-fieldset.form-group.webform-component--group.form-wrapper {
    @include susy-media(tablet) {
      margin-bottom: 20px;
    }
  }

  .webform-client-form {
    @include susy-media(tablet) {
      padding: 0 45px;
    }
  }

  .webform-component-markup {
    margin-bottom: 0;

    @include susy-media(tablet) {
      position: absolute;
      top: -75px;
      left: 0;
      right: 0;
    }
  }

  .form-submit {
    @extend .btn--white;

    @include susy-media(mobileOnly) {
      width: 100%;
    }
  }

  .title--large {
    line-height: 1;
    margin-bottom: 26px;

    @include susy-media(mobileOnly) {
      font-size: 37px;
      margin-bottom: 11px;
    }

    @include susy-media(mobilePortraitOnly) {
      font-size: 32px;
    }
  }
}


.webform-client-form-7 {
  .webform-component-fieldset {
    margin-bottom: 20px;
  }
  
  .captcha {
    margin-bottom: 20px;;
  }
}