// Texts
.text {
  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  // Dark Colors
  &--dark {
    color: $text-color;
  }

  &--pink {
    color: $pink;
  }

  // Light Colors
  &--white {
    color: $white;

    a {
      @extend %link--white;
    }
  }
}

// Backgrounds
.bg {
  &--image {
    background-color: $gray-f0;
    position: relative;

    &:before {
      content: '';
      bottom: 0;
      left: 0;
      position: absolute;
      width: 500px;
      height: 100%;
      background: url('../images/bg-video.png') no-repeat scroll left bottom transparent;
      opacity: .22;
      background-size: auto 100%;
      background-position: 70px 0;

      @include susy-media(mobileOnly) {
        width: 100%;
      }
    }
  }

  // Dark backgrounds
  &--primary {
    background-color: $primary-color;
  }

  // Light backgrounds
  &--white {
    background-color: $white;
  }

  &--pink {
    background-color: $pink-ec;
    @extend .text--white;
  }

  &--blue {
    background-color: $blue-1a;
    @extend .text--white;
  }

  &--gray {
    background-color: $gray-f1;
  }

  &--light-gray {
    background-color: $gray-f0;
  }

  //bgOverlay
  &--blue-overlay {
    @include gradient-overlay($blue-1a, .7);
  }

  //bgOverlay
  &--gray-overlay {
    @include gradient-overlay($gray-96, .7);
  }
}

// Helper class
.label {
  font-size: rem(18px);
  color: $blue-59;
}

.title {
  &--largest {
    font-size: 28px;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;

    @include susy-media(tablet) {
      font-size: 40px;
    }

    @include susy-media(desktop) {
      font-size: 50px;
    }

    @include susy-media(desktopWide) {
      font-size: 67px;
    }
  }

  &--large {
    font-size: 28px;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;

    @include susy-media(tablet) {
      font-size: 34px;
    }

    @include susy-media(desktop) {
      font-size:38px;
    }

    @include susy-media(desktopWide) {
      font-size: 50px;
    }
  }

  &--medium {
    font-size: 37px;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 18px;

    @include susy-media(mobilePortraitOnly) {
      font-size: 28px;
    }

    @include susy-media(tablet) {
      font-size: 28px;
    }

    @include susy-media(desktop) {
      font-size: 37px;
    }
  }

  &--small-large {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 0;
    line-height: 1.3;
    text-transform: uppercase;

    @include susy-media(tablet) {
      font-size: 21px;
    }

    @include susy-media(desktopWide) {
      letter-spacing: 3px;
    }
  }

  &--small {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 0;
    line-height: 1.3;
    text-transform: uppercase;

    @include susy-media(tablet) {
      font-size: 18px;
    }

    @include susy-media(desktopWide) {
      letter-spacing: 3px;
    }
  }

  &--tiny {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 0;
    line-height: 1.3;
    text-transform: uppercase;
  }
}

.video-wrap {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: rem(20px);

  video,
  iframe {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }

  &__poster {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    // transform: translateY(0);
    opacity: 1;
    transition: transform .8s ease;
    width: 100%;
    overflow: hidden;

    &:after {
      z-index: 2;
    }

    img {
      height: 100%;
      left: 0;
      max-width: inherit;
      position: absolute;
      width: auto;
      z-index: 1;
    }
  }

  &__icon {
    left: 50%;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  &.play-video {
    .video-wrap__poster {
      // transform: translateY(-100%);
      transition: transform .8s ease;
      opacity: 0;
    }

    video,
    iframe {
      opacity: 1;
    }
  }

  &--bigger {
    // Image style 960x674
    padding-bottom: 70.25%;
  }

  &.is-iframe-embed {
    video,
    iframe {
      opacity: 1;
    }
  }

  // Video wrap popup.
  &--popup {
    height: inherit;
    padding: 0;

    .video-wrap__icon {
      left: 0;
      top: auto;
      bottom: 0;
      transform: none;
    }

    .video-wrap__poster {
      position: relative;
      line-height: 0;
      cursor: inherit;
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }
}

.margin-top-over {
  margin-top: -30px;
}

.margin-bottom-over {
  margin-bottom: 50px;
}

.space-top-over {
  position: relative;
  top: -53px;

  @include susy-media(mobileOnly) {
    top: -20px
  }
}

.padding-space {
  @include susy-media(desktopWide) {
    padding: 0 82px;
  }
}

.list-style {
  ul {
    list-style: none;
    margin-bottom: 7px;

    li {
      position: relative;
      padding-left: 10px;
      margin-bottom: 6px;

      &:before {
        content: '>';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
      }
    }
  }
}

.field-name-field-pre-requisites {
  .field-label {
    margin-bottom: 7px;
    font-size: 14px;
  }

  .field-item {
    position: relative;
    padding-left: 10px;
    margin-bottom: 6px;
    font-size: 12px;

    &:before {
      content: '>';
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
    }
  }
}

.bg-dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, .75);
  visibility: hidden;
  transition: .5s;
  z-index: 102;
  display: none;
}

.is-show-popup,
.is-show-popup-login,
.is-show-comming-soon {
  transition: .5s;

  .bg-dark-overlay {
    opacity: 1;
    visibility: visible;
    transition: .5s;
    display: block;
  }
}

.is-show-popup {
  .popup-select-studio {
    top: 50%;
    transition: .5s;
  }
}

.is-show-popup-login {
  .popup-login {
    top: 50%;
    transition: .5s;
  }
}

.hidden-on-desktop {
  @include susy-media(desktop) {
    display: none;
  }
}

.hidden-on-tablet {
  @include susy-media(tablet) {
    display: none;
  }
}

.hidden-on-mobile-tablet {
  @include susy-media(mobileTabletOnly) {
    display: none;
  }
}

.hidden-on-mobile {
  @include susy-media(mobileOnly) {
    display: none;
  }
}

.section-title {
  font-size: 37px;

  @include susy-media(mobilePortraitOnly) {
    font-size: 28px;
  }
}

.block-system {
  .captcha {
    margin-bottom: 20px;
  }
}

.image-circle {
  width: 198px;
  height: 198px;
  border-radius: 100%;
  border: 10px solid $gray-f0;
  overflow: hidden;
  position: relative;

  @include susy-media(mobilePortraitOnly) {
    width: 150px;
    height: 150px;
  }

  @include susy-media(tablet) {
    width: 250px;
    height: 250px;
  }

  @include susy-media(desktop) {
    width: 300px;
    height: 300px;
  }

  @include susy-media(desktopWide) {
    width: 343px;
    height: 343px;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    height: 100%;
    width: auto;
    max-width: 700px;
  }
}

.img-circle {
  border-radius: 100%;
  border: 10px solid $gray-f0;
  width: 100% !important;
  height: auto !important;
}

.date-time {
  margin-bottom: 15px;
}

.phone,
a[href^="tel:"] {
  color: $white;

  @include susy-media(desktop) {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}

.item-list .pager {
  margin: 25px 0;

  @include susy-media(desktop) {
    margin: 25px 0 0;
  }

  li {
    margin: 0 12px;
    padding: 0;

    a:hover {
      color: $text-color;
    }
  }
}

.back-to-top {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 106;
  display: none;

  span {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $pink;
    color: $white;
    font-size: 20px;
    cursor: pointer;
    border-radius: 100%;

    @include susy-media(tablet) {
      width: 60px;
      height: 60px;
    }
  }
}

.link-all {
  a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}

.two-col {
  @include susy-media(tablet) {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    @include susy-media(tablet) {
      width: calc((100% - 22px) / 2);

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.img-group {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  width: 100%;

  img {
    max-height: 200px;
  }
}

.table-content {
  font-size: 13px;
}

.table-program {
  font-size: 15px;

  tbody {
    td,th {
      border: 0;
      vertical-align: middle;
    }
  }
}
