// TYPOGRAPHY
// Define all typographic styles in this file.

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  margin-bottom: rem(12px);
  text-transform: uppercase;
  line-height: 1;
}

h1 {
  font-size: 32px;
  
  @include susy-media(mobileLandscape) {
    font-size: 37px;
  }

  @include susy-media(tablet) {
    font-size: 45px;
  }

  @include susy-media(desktop) {
    font-size: 50px;
  }
}

h2 {
  // font-size: 37px;
  //
  // @include susy-media(tablet) {
  //   font-size: 45px;
  // }
  //
  // @include susy-media(desktop) {
  //   font-size: 50px;
  // }

  font-size: 28px;

  @include susy-media(tablet) {
    font-size: 32px;
  }

  @include susy-media(desktop) {
    font-size: 37px;
  }
}

h3 {
  font-size: 21px;

  @include susy-media(tablet) {
    font-size: 24px;
  }

  @include susy-media(desktop) {
    font-size: 28px;
  }
}

h4 {
  font-size: 19px;

  @include susy-media(tablet) {
    font-size: 21px;
  }
}

h5 {
  @extend .title--small;
  color: $blue-59;
}

h6 {
  @extend .title--tiny;
  color: $blue-59;
}

%link,
.link {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;

  &:active,
  &:hover {
    color: $link-color-hover;
    cursor: pointer;
  }

  &--white {
    color: $white;

    &:active,
    &:hover {
      color: darken($white, 15%);
    }
  }
}

a {
  @extend %link;
}

p {
  margin-bottom: 12px;
}

em {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
}

small {
  font-size: smaller;
}

abbr {
  border-bottom: 1px dotted $text-color;
  cursor: help;
}

tt,
code,
kbd,
samp {
  font-family: monospace;
}

ol,
ul {
  padding: 0;
  margin-bottom: rem(15px);
}

label {
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-block;
}

blockquote {
  p {
    display: inline;
  }

  &:before {
    content: '\201c';
  }

  &:after {
    content: '\201d';
  }
}

iframe {
  max-width: 100%;
}

figure {
  line-height: 0;
}
