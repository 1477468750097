.guide-box {
  padding: 50px 0;
  background-color: #f0f2f5;

  @include susy-media(mobileOnly) {
    padding: 5px 0 22px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 59px;

    @include susy-media(mobileTabletOnly ) {
      display: none;
    }

    @include susy-media(desktopWide) {
      padding: 0 70px;
    }
  }

  &__left,
  &__right {
    width: 33%;
    margin-top: 27px;
  }

  &__middle {
    width: 417px;
    text-align: center;
    padding: 0 10px;

    @include susy-media(desktopWide) {
      min-width: 413px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .guide-box__item {
    position: relative;

    &:before {
      position: absolute;
      background: url('../images/point-blue.png') no-repeat scroll 0 0 transparent;
      background-size: auto 100%;
      content: '';
      right: -145px;
      top: 32px;
      transform: rotate(-150deg);
      width: 135px;
      height: 48px;
    }

    &:hover {
      cursor: pointer;

      &:before {
        background-image: url('../images/point-pink.png');
      }
    }
  }

  &__left {
    text-align: right;

    @include susy-media(desktopWide) {
      margin-top: 37px;
    }

    .guide-box__item {
      &:nth-child(2):before {
        right: -183px;
        top: -30px;
        transform: rotate(-192deg);
        width: 173px;
      }

      &:nth-child(3):before {
        right: -192px;
        top: -26px;
        transform: rotate(-190deg);
        width: 182px;
      }

      &:nth-child(4):before {
        right: -215px;
        top: -55px;
        transform: rotate(-202deg);
        width: 216px
      }
    }
  }

  &__right {
    .guide-box__item {
      &:before{
        right: unset;
        width: 176px;
        left: -192px;
        transform: rotate(7deg);
        top: -7px;
      }

      &:nth-child(2):before {
        left: -185px;
        top: 18px;
        transform: rotate(-12deg);
        width: 175px;
      }

      &:nth-child(3):before {
        left: -175px;
        top: -20px;
        transform: rotate(10deg);
        width: 160px;
      }

      &:nth-child(4):before {
        left: -245px;
        top: -100px;
        transform: rotate(45deg);
        width: 265px
      }

      &:nth-child(5):before {
        left: -122px;
        top: 0;
        transform: rotate(-5deg);
        width: 109px
      }
    }
  }

  &__item {
    margin-bottom: 29px;
  }

  &__body {
    font-size: 14px;

    @include susy-media(desktopWide) {
      font-size: 16px;
    }
  }

  &__title {
    color: $pink;
    margin-bottom: 12px;

    @include susy-media(mobileOnly) {
      font-size: 21px;
    }
  }

  &__group-title {
    h5 {
      @extend .title--tiny;
    }

    h3 {
      @extend .title--medium;
    }
  }

  &__mobile {
    text-align: center;
    margin-bottom: 40px;

    @include susy-media(mobileLandscape) {
      display: flex;
      align-items: center;
      text-align: left;
    }

    @include susy-media(desktop) {
      display: none;
    }

    .content {
      text-align: center;

      @include susy-media(mobileLandscape) {
        display: flex;
        align-items: center;
        text-align: left;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: 158px;
    margin-right: 10px;
    text-align: center;

    @include susy-media(mobilePortraitOnly) {
      min-width: 110px;
      margin: 0 auto;

      img {
        display: inline-block;
      }
    }

    @include susy-media(tablet) {
      min-width: 300px;
    }
  }

  &__content {
    @include susy-media(mobileOnly) {
      width: calc(100% - 160px);
    }

    @include susy-media(mobilePortraitOnly) {
      width: 100%;
    }
  }

  &__link {
    margin-top: 30px;
  }
}
