.field-item:last-child {
  .text-image {
    margin-bottom: 25px;
  }
}

.text-image {
  padding: 41px 0 27px;

  @include susy-media(tablet) {
    padding: 98px 0;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;

    @include susy-media(tablet) {
      padding: 0 50px;
    }

    @include susy-media(desktop) {
      align-items: center;
    }

    @include susy-media(desktopWide) {
      padding: 0 80px 0 113px;
    }
  }

  &__content {
    width: 100%;
    // padding: 46px 40px 0 0;
    order: 2;

    @include susy-media(desktop) {
      width: 35%;
      order: 1;
      padding: 0 40px 0 0;
    }

    @include susy-media(desktopWide) {
      width: 27%;
    }
  }

  &__image {
    width: 100%;
    display: flex;
    order: 1;
    margin-bottom: 35px;

    @include susy-media(desktop) {
      align-items: center;
      margin-bottom: 0;
      order: 2;
      width: 65%;
      justify-content: space-between;
    }

    @include susy-media(desktopWide) {
      width: 73%;
    }

    img {
      height: auto;
      width: 100%;
    }

    > div {
      line-height: 0;
      box-shadow: 0 0 15px -4px #000;
    }

    &__small {
      margin-right: 16px;
      width: 45%;

      @include susy-media(tablet) {
        margin-right: 30px;
      }
    }

    &__large {
      width: 60.4%;
    }
  }

  &__body {
    // line-height: 1.6;
    // font-size: 21px;
  }

  &__title {
    margin-bottom: 15px;
  }

  .video-wrap {
    padding: 0;
    height: auto;
    margin: 0;

    .video-wrap__poster {
      position: relative;

      img {
        position: relative;
      }
    }
  }
}
