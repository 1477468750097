.title-text {
  padding: 99px 0 8px;

  @include susy-media(mobileOnly) {
    padding: 32px 0 8px;
  }

  &__group-title {
    @include susy-media(mobileOnly) {
      padding-right: 0;
      margin-bottom: 22px;
    }

    @include susy-media(tablet) {
      width: 41%;
      float: left;
      padding-left: 35px
    }

    .title-text__title {
      line-height: 1.2;
      margin-top: -2px;
      font-size: 32px;

      @include susy-media(mobilePortraitOnly) {
        font-size: 28px;
      }

      @include susy-media(desktop) {
        font-size: 37px;
      }
    }
  }

  &__body {
    @include susy-media(tablet) {
      float: left;
      width: 59%;
      padding-left: 7px;
      padding-top: 18px;
    }
  }

  &--type1 {
    padding: 58px 0 51px;
  }

  &--type2 {
    padding: 37px 0 25px;

    @include susy-media(tablet) {
      padding: 79px 0 25px;
    }

    .container-medium {
      @extend .padding-space;

      @include susy-media(mobileOnly) {
        padding: 0 20px;
        text-align: center;
      }
    }

    .title-text__title {
      color: $primary-color;

      @include susy-media(mobileOnly) {
        margin-bottom: 28px;
      }
    }

    .title-text__group-title {
      @include susy-media(tablet) {
        width: 28%;
        float: left;
      }

      @include susy-media(desktop) {
        padding-left: 61px;
      }
    }

    .title-text__body {
      @include susy-media(tablet) {
        float: left;
        width: 72%;
        padding-left: 7px;
        padding-top: 0;
      }
    }
  }
}
