.box-group-content {
  padding: 45px 0 30px;

  .view-resource {
    @include susy-media(desktop) {
      display: flex;
    }

    .block-poledivas-entity {
      @include susy-media(mobileOnly) {
        position: relative;

        &:before {
          position: absolute;
          top: -50px;
          left: -20px;
          right: -20px;
          content: '';
          background-color: $gray-f0;
          bottom: -15px;
        }

        .content {
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  .view-filters {
    @include susy-media(desktop) {
      padding-right: 30px;
      float: left;
      width: 25%;
      min-width: 279px;
    }

    @include susy-media(desktopWide) {
      padding-right: 49px;
    }
  }

  .content-group {
    @include susy-media(desktop) {
      float: left;
      width: 75%;
      padding-left: 6px;
    }
  }

  .views-exposed-widgets {
    margin: 0;
  }

  .views-exposed-form {
    @include susy-media(mobileTabletOnly) {
      background: $gray-f0;
      padding: 8px 20px;
      border-radius: 15px;
      margin-bottom: 26px;

      .views-widget {
        margin-top: 7px;
        display: none;
      }
    }

    .views-exposed-widget{
      float: none;
      padding: 0;
    }

    label {
      font-weight: 500;
      text-align: center;
      display: block;
      line-height: 1;
      margin-bottom: 17px;

      @include susy-media(mobileTabletOnly) {
        line-height: 1.2;
        margin-bottom: 0;
        font-size: 16px;
        padding: 4px 0;
      }
    }
  }

  .pager {
    display: none;
  }
}

.facebook-group {
   background: #f1f2f6;
   padding: 26px 16px 20px;
   margin-bottom: 37px;
   text-align: center;

   @include susy-media(mobileTabletOnly) {
     background: none;
     padding: 0;
   }

   @include susy-media(mobileOnly) {
     position: relative;

     &:before {
       position: absolute;
       content: "";
       top: -45px;
       left: -20px;
       right: -20px;
       bottom: -15px;
       background-color: $gray-f0;
     }

     > h3,
     > div {
      position: relative;
      z-index: 1;
     }
   }

   &__title {
     margin-bottom: 20px;

     @include susy-media(mobileTabletOnly) {
       font-size: 25px;
       margin-bottom: 18px;
     }
   }

   &__description {
     margin-bottom: 27px;

     @include susy-media(mobileTabletOnly) {
       margin-bottom: 25px;
     }
   }

   &__list {
     display: flex;
     justify-content: center;

     @include susy-media(mobileOnly) {
       flex-wrap: wrap;
     }
   }

   &__item {
     position: relative;
     line-height: 0;
     margin-right: 20px;
     background-color: $text-color;

     @include susy-media(mobileOnly) {
       width: calc((100% - 20px) / 3);
       margin-bottom: 10px;
       margin-right: 10px;

       &:nth-child(3n) {
         margin-right: 0;
       }
     }

     img {
       opacity: .35;
       min-width: 100%;
       min-height: 100%;
     }

     &:after {
       content: '';
       position: absolute;
       left: 0;
       right: 0;
       bottom: 0;
       top: 0;
       background-color: rgba($text-color, .7);
     }

     &:before {
       position: absolute;
       background: url('../images/fb-icon.png') no-repeat scroll 0 0 transparent;
       background-size: 100% auto;
       left: 50%;
       top: 50%;
       margin-left: -18px;
       margin-top: -35px;
       width: 37px;
       height: 39px;
       content: '';
       z-index: 2;

       @include susy-media(mobilePortraitOnly) {
         margin-left: -15px;
         margin-top: -25px;
         width: 30px;
         height: 32px;
       }

       @include susy-media(tablet) {
         margin-left: -15px;
         margin-top: -25px;
         width: 30px;
         height: 32px;
       }

       @include susy-media(desktopWide) {
         margin-left: -18px;
         margin-top: -35px;
         width: 37px;
         height: 39px;
       }
     }

     &:last-child {
       margin-right: 0;
     }

     > span {
       position: absolute;
       bottom: 11px;
       left: 0;
       right: 0;
       color: $white;
       line-height: 1.5;
       text-transform: uppercase;
       font-weight: 800;
       font-size: 16px;
       z-index: 2;

       @include susy-media(mobilePortraitOnly) {
         font-size: 11px;
         bottom: 4px;
       }

       @include susy-media(tablet) {
         font-size: 12px;
         bottom: 4px;
       }

       @include susy-media(desktopWide) {
         font-size: 16px;
         bottom: 12px;
       }
     }
   }
}

.grid-content {
  display: flex;
  flex-wrap: wrap;

  &__image {
    line-height: 0;

    img {
      width: 100%;
      height: auto;
    }

    .video-wrap {
      padding: 0;
      height: auto;
      margin-bottom: 0;
    }
  }
  &__item {
    width: calc((100% - 10px) / 2);
    margin-right: 10px;
    margin-bottom: 15px;
    background-color: $gray-f0;
    position: relative;
    padding-bottom: 35px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    @include susy-media(mobilePortraitOnly) {
      width: 100%;
      margin-right: 0;
    }

    @include susy-media(tablet) {
      float: left;
      width: calc((100% - 60px) / 3);
      margin-right: 30px;
      margin-bottom: 30px;

      &:nth-child(2n) {
        margin-right: 30px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &:last-child {
      @include susy-media(mobilePortraitOnly) {
        margin-bottom: 0;
      }
    }

    .node {
      position: static;
    }

    .grid-content__link {
      background-color: #5974db;
      color: $white;
      display: block;
      padding: 8px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    &:hover .grid-content__link {
      background-color: darken($blue-59, 10%);
    }
  }

  &__title {
    padding: 14px 20px 22px;
    line-height: 1.2;
    margin-bottom: 0;
  }

  &__link_all {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .video-wrap {
    position: relative;

    &:before {
      @extend %icons;
      @extend .btn--play;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-size: 96px 96px;

      @include susy-media(mobileLandscape) {
        background-size: 66px auto;
      }

      @include susy-media(desktopWide) {
        background-size: 96px auto;
      }
    }
  }
}
