//Convert px to em
@function em($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0;
}

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $base-font-size) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;

  @return $value;
}


// REM CALC

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
//
//   rem-calc(10 20 30px 40);
//
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
//
//   rem-calc((10, 20, 30, 40px));
//
// Optionally call with a different base (eg: 8px) to calculate rem.
//
//   rem-calc(16px 32px 48px, 8px);
//
// If you require to comma separate your list
//
//   rem-calc((16px, 32px, 48), 8px);
@function rem($values, $base-value: $base-font-size) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $rem-values: ();
  @for $i from 1 through $max {
    $rem-values: append($rem-values, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $rem-values;
}
