// COLORS
// Define all color variables in this document
// Use http://chir.ag/projects/name-that-color/ for naming a color.

$black: #000;
$white: #fff;
$silver: #ccc;
$pigeon-post: #aec9de;
$pigeon-post-light: rgba($pigeon-post, .75);

// Gray
$gray: #808080;
$gray-e1: #e1e2e6;
$gray-96: #969799;
$gray-f0: #f0f2f5;
$gray-f1: #F1F2F6;
$gray-f3: #f3f5f9;

// Red
$red: #8c1515;

$blue: #182759;
$dark-blue: #191F51;
$blue-59: #5975dc;
$blue-1a: #1a1f51;
$pink: #ec008c;
$pink-ec: #EC008B;

// Brand colors
$primary-color: $pink;
$text-color: $blue;

// Link color
$link-color: $primary-color;
$link-color-hover: lighten($primary-color, 25%);

// Button color
$btn-color: $blue-59;
$btn-color-hover: darken($blue-59, 10%);
